import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { doCreateFleet, doGetFleet, doGetFleets, doUpdateFleet } from './thunk';

const isActionWithPending = (action) => {
  return action.type && action.type.startsWith('Fleets/') && action.type.endsWith('/pending');
};

const isActionWithFulfilled = (action) => {
  return action.type && action.type.startsWith('Fleets/') && action.type.endsWith('/fulfilled');
};

const isActionWithFailed = (action) => {
  return action.type && action.type.startsWith('Fleets/') && action.type.endsWith('/rejected');
};

const fleetsAdapter = createEntityAdapter({
  selectId: (item) => item.id,
});

const fleetSlice = createSlice({
  name: 'fleet',
  initialState: {
    action: null,
    isFetching: false,
    currentFleet: null,
    status: 'idle',
    metadata: {},
    entities: [],
    ids: [],
    item: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetFleets.pending, (state) => {
      state.action = 'getall';
      state.status = 'loading';
    });
    builder.addCase(doGetFleets.fulfilled, (state, action) => {
      const { fleets, pagination } = action.payload;
      state.status = 'succeeded';
      state.metadata = pagination;
      fleetsAdapter.setAll(state, fleets);
    });
    builder.addCase(doGetFleets.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doGetFleet.pending, (state) => {
      state.action = 'getOne';
      state.status = 'loading';
    });
    builder.addCase(doGetFleet.fulfilled, (state, action) => {
      state.status = 'succeeded';
      if (action.payload) {
        state.currentFleet = action.payload;
      }
    });
    builder.addCase(doGetFleet.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doCreateFleet.pending, (state) => {
      state.action = 'create';
      state.status = 'loading';
    });
    builder.addCase(doCreateFleet.fulfilled, (state) => {
      state.status = 'succeeded';
    });
    builder.addCase(doCreateFleet.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doUpdateFleet.pending, (state) => {
      state.action = 'update';
      state.status = 'loading';
    });
    builder.addCase(doUpdateFleet.fulfilled, (state) => {
      state.status = 'succeeded';
    });
    builder.addCase(doUpdateFleet.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addMatcher(isActionWithPending, (state) => {
      state.isFetching = true;
      state.message = null;
    });
    builder.addMatcher(isActionWithFulfilled, (state) => {
      state.isFetching = false;
    });
    builder.addMatcher(isActionWithFailed, (state) => {
      state.isFetching = false;
      state.message = null;
    });
  },
});

export const {
  selectAll: selectAllFleets,
  selectById: selectFleetById,
  selectIds: selectFleetIds,
} = fleetsAdapter.getSelectors((state) => state.fleet);
const { reducer: FleetReducer } = fleetSlice;
export default FleetReducer;
