import { Table } from 'antd';
import React from 'react';
import moment from 'moment';
import { DAYTIME_FORMAT, ORDER_STATUSES_DISPLAY } from '../../constants';

export default function RouteOrdersTable({ dataSource, selectedRowKeys, onSelectChange, ...props }) {
  const columns = [
    {
      title: 'Order ID',
      dataIndex: 'orderCode',
      key: 'orderCode',
      fixed: 'left',
      width: 200,
      className: 'border-right',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 200,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 250,
      render: (value) => {
        return value ? moment(value).format(DAYTIME_FORMAT) : '';
      },
    },
    {
      title: 'Pickup Address',
      dataIndex: 'pickupAddress',
      key: 'pickupAddress',
      width: 700,
    },
    {
      title: 'Delivery Address',
      dataIndex: 'deliveryAddress',
      key: 'deliveryAddress',
      width: 700,
    },
    {
      title: 'Delivery Phone',
      dataIndex: 'deliveryPhone',
      key: 'deliveryPhone',
      width: 200,
    },
    {
      title: 'Pickup Time Window',
      dataIndex: 'pickupTimeWindow',
      width: 200,
      ellipsis: true,
      render(_value, record) {
        return (record.pickupTimeStart || '') + ' - ' + (record.pickupTimeEnd || '');
      },
    },
    {
      title: 'Delivery Time Window',
      dataIndex: 'deliveryTimeWindow',
      width: 200,
      ellipsis: true,
      render(_value, record) {
        return (record.deliveryTimeStart || '') + ' - ' + (record.deliveryTimeEnd || '');
      },
    },
    {
      title: 'Load',
      dataIndex: 'load',
      key: 'load',
      width: 200,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      fixed: 'right',
      width: 200,
      className: 'border-left',
      render: (value) => {
        return <span className={'status order ' + value.toLowerCase()}>{ORDER_STATUSES_DISPLAY[value]}</span>;
      },
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div className="table-orders table-common">
      <Table
        columns={columns}
        rowSelection={rowSelection}
        dataSource={dataSource}
        scroll={{ x: 'max-content', y: 'max-content' }}
        pagination={false}
        // bordered
        {...props}
      />
    </div>
  );
}
