import { Table } from 'antd';
import React from 'react';
import moment from 'moment';
import { DAYTIME_FORMAT, FLEET_STATUSES, FLEET_TAG } from '../../constants';

const initColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
    className: 'border-right',
    width: 200,
  },
  {
    title: 'Tag',
    dataIndex: 'tag',
    key: 'tag',
    width: 70,
    render: (value) => {
      return <span className={'status ' + value.toLowerCase()}>{FLEET_TAG[value]}</span>;
    },
  },
  {
    title: 'Hub',
    key: 'hubIndex',
    width: 200,
    render: (record) => {
      return record.hub?.name || '';
    },
  },
  {
    title: 'Access Code',
    dataIndex: 'accessCode',
    key: 'accessCode',
  },
  {
    title: 'Weight',
    dataIndex: 'weight',
    key: 'weight',
    render: (value) => {
      if (value) {
        return `${value} kg`;
      }
    },
  },
  {
    title: 'Volume',
    dataIndex: 'volume',
    key: 'volume',
    render: (value) => {
      if (value) {
        return `${value} m3`;
      }
    },
  },
  {
    title: 'Updated At',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    width: 250,
    render: (value) => {
      return value ? moment(value).format(DAYTIME_FORMAT) : '';
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    fixed: 'right',
    width: 200,
    className: 'border-left',
    render: (value) => {
      return <span className={'status fleet ' + value.toLowerCase()}>{FLEET_STATUSES[value]}</span>;
    },
  },
];

export default function FleetsTable({ withAction, dataSource, selectedRowKeys, onSelectChange, columns, ...props }) {
  const defaultColumns = [...(columns || initColumns)];

  if (withAction) {
    defaultColumns.push({
      key: 'action',
      width: 120,
      className: 'border-left',
      fixed: 'right',
      render(record) {
        return withAction(record);
      },
    });
  }

  const rowSelection =
    selectedRowKeys && onSelectChange
      ? {
          selectedRowKeys,
          onChange: onSelectChange,
        }
      : undefined;

  return (
    <div className="table-job-orders table-common">
      <Table
        columns={defaultColumns}
        rowSelection={rowSelection}
        dataSource={dataSource}
        rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-dark' : 'table-row-light')}
        {...props}
      />
    </div>
  );
}
