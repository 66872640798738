import React, { useEffect } from 'react';
import { Button, Form, Input, message, Select, Spin } from 'antd';
import { FLEET_STATUSES, FLEET_TAG } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { doGetHubs } from '../../modules/hubs/stores/thunk';
import { selectAllHubs } from '../../modules/hubs/stores/slice';

const initForm = {
  name: '',
  tag: 'BIKE',
  hubId: undefined,
  weight: undefined,
  volume: undefined,
  status: 'FUNCTIONAL',
};
const FleetForm = ({ handleSubmit, action, fleet, handleCancel }) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const isFetching = useSelector((state) => state.hub.isFetching);

  useEffect(() => {
    dispatch(doGetHubs({ page: 1, take: 100000 }));
  }, []);

  const hubs = useSelector((state) => selectAllHubs(state));

  // eslint-disable-next-line no-unused-vars
  const [messageApi, contextHolder] = message.useMessage();

  const setParams = (values) => {
    const params = { ...values };
    if (params.weight) {
      params.weight = Number(params.weight);
    }
    if (params.volume) {
      params.volume = Number(params.volume);
    }

    return params;
  };
  const onFinishForm = (values) => {
    handleSubmit && handleSubmit(setParams(values));
  };

  useEffect(() => {
    if (action === 'new') {
      form.setFieldsValue(initForm);
    } else if (fleet) {
      form.setFieldsValue({
        ...fleet,
      });
    }
  }, [action, fleet]);

  return (
    <Spin spinning={isFetching}>
      <div className="FleetForm">
        {contextHolder}
        <div className="FormHeader"></div>
        <div className="FormContent">
          <Form
            form={form}
            scrollToFirstError
            layout="vertical"
            requiredMark={false}
            colon={false}
            onFinish={onFinishForm}
          >
            <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Required' }]}>
              <Input placeholder="Name" />
            </Form.Item>
            <Form.Item label="Tag" name="tag" rules={[{ required: true, message: 'Required' }]}>
              <Select
                placeholder="Select Tag"
                options={Object.keys(FLEET_TAG).map((key) => ({
                  value: key,
                  label: FLEET_TAG[key],
                }))}
              />
            </Form.Item>
            <Form.Item label="Hub" name="hubId" rules={[{ required: true, message: 'Required' }]}>
              <Select
                placeholder="Select Hub"
                options={hubs.map((hub) => ({
                  value: hub.id,
                  label: hub.name,
                }))}
              />
            </Form.Item>
            <Form.Item label="Weight" name="weight">
              <Input type="number" placeholder="Weight" suffix="kg" />
            </Form.Item>
            <Form.Item
              label="Volume"
              name="volume"
              rules={[
                {
                  validator: (_, value) => {
                    if (!value || (value && value <= 75)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(new Error('Volume must be less than or equal to 75'));
                    }
                  },
                },
              ]}
            >
              <Input type="number" max={75} placeholder="Volume" suffix="m3" />
            </Form.Item>
            <Form.Item label="Status" name="status" rules={[{ required: true, message: 'Required' }]}>
              <Select
                placeholder="Select Status"
                options={Object.keys(FLEET_STATUSES).map((key) => ({
                  value: key,
                  label: FLEET_STATUSES[key],
                }))}
              />
            </Form.Item>
          </Form>
        </div>
        <div className="FormFooter">
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="primary" block size="large" onClick={() => form.submit()}>
            {action === 'new' ? 'Create' : 'Update'}
          </Button>
        </div>
      </div>
    </Spin>
  );
};

export default FleetForm;
