/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAllRoutes } from '../modules/routes/stores/slice';
import { DATE_BACKEND_FORMAT, PAGE_SIZE, PAGE_SIZE_OPTIONS } from '../constants';
import { doGetRoutes } from '../modules/routes/stores/thunk';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import RoutesTable from '../components/Tables/RoutesTable';
import { Button, Input } from 'antd';
import { ReactSVG } from 'react-svg';
import queryString from 'query-string';
import moment from 'moment';

const RoutesPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const routes = useSelector(selectAllRoutes);

  const { metadata, isFetching } = useSelector((state) => state.route);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const pushTo = (pushProps) => {
    navigate({
      pathname: pushProps.path || location.pathname,
      search: queryString.stringify(pushProps.query || {}, {
        skipNull: true,
      }),
      state: pushProps.state,
    });
  };

  const query = Object.fromEntries(searchParams);
  const currentPage = query.current || 1;
  const itemsPerPage = query.pageSize || PAGE_SIZE;
  const pagination = {
    current: currentPage || 1,
    pageSize: itemsPerPage,
    locale: { items_per_page: 'Routes/Page' },
    total: metadata.totalItems || routes.length,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    showSizeChanger: true,
    showTotal: (total) => `${total} Routes`,
  };

  useEffect(() => {
    dispatch(doGetRoutes({ page: currentPage, take: itemsPerPage, ...query }));
  }, []);

  const handleFilter = (params, updatePath) => {
    delete params.pageSizeOptions;
    if (updatePath) {
      pushTo({ query: params });
    }
    const newCurrentPage = 1;
    const newItemsPerPage = Number(params?.pageSize) || PAGE_SIZE;
    dispatch(
      doGetRoutes({
        page: newCurrentPage,
        take: newItemsPerPage,
        ...params,
      }),
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch && handleSearch();
    }
  };

  const handleChangeSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const handleTableChange = (params) => {
    const newParams = { ...query, ...params };
    handleFilter(newParams, true);
  };

  const handleSearch = () => {
    handleFilter({ q: searchValue || '' }, true);
  };

  const withAction = (record) => {
    return (
      <div className="actions">
        <Link to={`/admin/route-plans?date=${moment(record.date).format(DATE_BACKEND_FORMAT)}`}>
          <Button type="link" className="edit">
            <ReactSVG className="icon" src="/icons/route-plans.svg" />
          </Button>
        </Link>
        <Button type="link" className="more">
          <ReactSVG className="icon" src="/icons/more.svg" />
        </Button>
      </div>
    );
  };

  return (
    <div className="RoutesPage Content">
      <div className="ContentHeader">
        <div className="HeaderTitle">Routes</div>
        <div className="HeaderToolBar"></div>
      </div>
      <div className="page-header-table">
        <div className="search">
          <Input
            onChange={handleChangeSearch}
            onBlur={handleSearch}
            placeholder="Search"
            onKeyDown={handleKeyDown}
            suffix={<img src="/icons/search.svg" />}
            disabled={isFetching}
            value={searchValue}
          />
        </div>
        <div className="buttons">
          {selectedRowKeys.length > 0 && (
            <Button className="FilterButton ButtonFilter">
              {selectedRowKeys?.length > 0 && <div className="count">{selectedRowKeys.length}</div>}
              Action <ReactSVG className="icon" src="/icons/arrow_drop_down.svg" />
            </Button>
          )}

          <Button className="FilterButton ButtonFilter">
            Filter
            <ReactSVG className="icon" src="/icons/filter_list.svg" />
          </Button>
        </div>
      </div>
      <div className="ContentMain">
        <RoutesTable
          dataSource={routes}
          pagination={pagination}
          rowKey="id"
          isFetching={isFetching}
          onSelectChange={onSelectChange}
          selectedRowKeys={selectedRowKeys}
          withAction={withAction}
          onChange={handleTableChange}
        />
      </div>
    </div>
  );
};

export default RoutesPage;
