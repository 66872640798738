import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Spin, Row, Col, DatePicker, TimePicker, InputNumber, Modal, Select } from 'antd';
import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { isEmpty } from 'lodash';
import PhoneInput from 'react-phone-input-2';

import './Order.scss';
import { DATE_BACKEND_FORMAT, DAY_FORMAT, TIME_FORMAT } from '../../constants';
import AddressInput from '../Inputs/AddressInput';
import { useDispatch, useSelector } from 'react-redux';
import { doGetHubs } from '../../modules/hubs/stores/thunk';
import { selectAllHubs } from '../../modules/hubs/stores/slice';
import TagInput from '../Elements/TagInput';

const initStateModal = {
  open: false,
};

const OrderForm = ({ isFetching, handleSubmit }) => {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [countryPickupPhone, setCountryPickupPhone] = useState();
  const [countryDeliveryPhone, setCountryDeliveryPhone] = useState();
  const [types, setTypes] = useState([]);
  const [stateModal, setStateModal] = useState(initStateModal);

  const hubs = useSelector(selectAllHubs);

  const handleOnFinish = async () => {
    const params = setParams(values);
    handleSubmit && handleSubmit(params);
  };

  const handleOnFinishSelectedHub = (values) => {
    const hub = hubs.find((hub) => hub.id == values.hubId);
    if (hub) {
      form.setFieldsValue({
        pickupAddress: {
          address: hub.startAddress,
          latLng: {
            lat: hub.startAddressLat,
            lng: hub.startAddressLng,
          },
        },
        pickupName: hub.name,
      });
    }
    setStateModal({
      ...stateModal,
      open: false,
    });
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(doGetHubs({ take: 1000000 }));
  }, []);
  const setParams = (values) => {
    const params = { ...values };
    params.pickupPhone =
      !isEmpty(values.pickupPhone) && countryPickupPhone?.dialCode !== values.pickupPhone
        ? `${values.pickupPhone.includes('+') ? '' : '+'}${values.pickupPhone}`
        : null;
    params.deliveryPhone =
      !isEmpty(values.deliveryPhone) && countryDeliveryPhone?.dialCode !== values.deliveryPhone
        ? `${values.deliveryPhone.includes('+') ? '' : '+'}${values.deliveryPhone}`
        : null;
    params.pickupAddress = values.pickupAddress?.address;
    params.pickupAddressLat = values.pickupAddress?.latLng?.lat;
    params.pickupAddressLng = values.pickupAddress?.latLng?.lng;
    params.pickupTimeStart = values.pickupTimeStart ? values.pickupTimeStart.format(TIME_FORMAT) : null;
    params.pickupTimeEnd = values.pickupTimeEnd ? values.pickupTimeEnd.format(TIME_FORMAT) : null;
    params.pickupDuration = values.pickupDuration ? Number(values.pickupDuration) : null;
    params.deliveryAddress = values.deliveryAddress?.address;
    params.deliveryAddressLat = values.deliveryAddress?.latLng?.lat;
    params.deliveryAddressLng = values.deliveryAddress?.latLng?.lng;
    params.deliveryTimeStart = values.deliveryTimeStart ? values.deliveryTimeStart.format(TIME_FORMAT) : null;
    params.deliveryTimeEnd = values.deliveryTimeEnd ? values.deliveryTimeEnd.format(TIME_FORMAT) : null;
    params.deliveryDuration = values.deliveryDuration ? Number(values.deliveryDuration) : null;
    params.deliveryDate = values.deliveryDate ? moment(values.deliveryDate).format(DATE_BACKEND_FORMAT) : null;
    params.sizeLength = values.sizeLength ? Number(values.sizeLength) : null;
    params.sizeWidth = values.sizeWidth ? Number(values.sizeWidth) : null;
    params.sizeHeight = values.sizeHeight ? Number(values.sizeHeight) : null;
    params.weight = values.weight ? Number(values.weight) : null;
    params.types = types;

    return params;
  };

  const handleOnChangePickupFrom = (value) => {
    form.setFieldsValue({ pickupTimeStart: value });
    if (isEmpty(value) || isEmpty(form.getFieldValue('pickupTimeEnd'))) {
      resetErrorPickupTime();
      return;
    }
    if (form.getFieldValue('pickupTimeEnd') < value) {
      form.setFields([
        {
          name: 'pickupTimeStart',
          errors: ['Shift End must be less than Shift Start'],
        },
      ]);
    }
  };

  const handleOnChangePickupTo = (value) => {
    form.setFieldsValue({ pickupTimeEnd: value });
    if (isEmpty(value) || isEmpty(form.getFieldValue('pickupTimeStart'))) {
      resetErrorPickupTime();
      return;
    }
    if (form.getFieldValue('pickupTimeStart') > value) {
      form.setFields([
        {
          name: 'pickupTimeEnd',
          errors: ['Pickup To must be greater than Pickup From'],
        },
      ]);
    }
  };

  const resetErrorPickupTime = () => {
    form.setFields([
      {
        name: 'pickupTimeStart',
        errors: [],
      },
      {
        name: 'pickupTimeEnd',
        errors: [],
      },
    ]);
  };

  const handleOnChangeDeliveryFrom = (value) => {
    form.setFieldsValue({ deliveryTimeStart: value });
    if (isEmpty(value) || isEmpty(form.getFieldValue('deliveryTimeEnd'))) {
      resetErrorDeliveryTime();
      return;
    }
    if (form.getFieldValue('deliveryTimeEnd') < value) {
      form.setFields([
        {
          name: 'deliveryTimeStart',
          errors: ['Delivery From must be less than Delivery To'],
        },
      ]);
    }
  };

  const handleOnChangeDeliveryTo = (value) => {
    form.setFieldsValue({ deliveryTimeEnd: value });
    if (isEmpty(value) || isEmpty(form.getFieldValue('deliveryTimeStart'))) {
      resetErrorDeliveryTime();
      return;
    }
    if (form.getFieldValue('deliveryTimeStart') > value) {
      form.setFields([
        {
          name: 'deliveryTimeEnd',
          errors: ['Delivery To must be greater than Delivery From'],
        },
      ]);
    }
  };

  const resetErrorDeliveryTime = () => {
    form.setFields([
      {
        name: 'deliveryTimeStart',
        errors: [],
      },
      {
        name: 'deliveryTimeEnd',
        errors: [],
      },
    ]);
  };

  const onAddFromHubs = () => {
    form2.resetFields();
    setStateModal({
      ...stateModal,
      open: true,
    });
  };

  return (
    <div className="OrderForm">
      <Spin spinning={isFetching}>
        <Form
          form={form}
          scrollToFirstError
          layout="vertical"
          hideRequiredMark
          colon={false}
          onFinish={handleOnFinish}
          initialValues={{
            pickupAddress: {},
            deliveryAddress: {},
          }}
        >
          <div className="FormContent">
            <div className="Section">
              <div className="SectionHeader">
                <div className="Left">Pickup</div>
                <div className="Right">
                  <Button type="link" ghost onClick={() => onAddFromHubs()}>
                    Add from Hubs
                    <ReactSVG className="icon" src="/icons/all_inbox.svg" />
                  </Button>
                </div>
              </div>
              <div className="SectionContent">
                <Row gutter={24}>
                  <Col span={6}>
                    <Form.Item label="Pickup Name" name="pickupName" rules={[{ required: true, message: 'Required' }]}>
                      <Input placeholder="Pickup Name" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Pickup Phone Number" name="pickupPhone">
                      <PhoneInput
                        country={'us'}
                        size="normal"
                        prefix="+"
                        specialLabel=""
                        countryCodeEditable
                        enableSearch
                        disableSearchIcon
                        onChange={(value, country) => {
                          setCountryPickupPhone(country);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Pickup From" name="pickupTimeStart">
                      <TimePicker
                        placeholder="Pickup From"
                        format={TIME_FORMAT}
                        minuteStep={30}
                        style={{ width: '100%' }}
                        onChange={handleOnChangePickupFrom}
                        onSelect={handleOnChangePickupFrom}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Pickup To" name="pickupTimeEnd">
                      <TimePicker
                        placeholder="Pickup To"
                        format={TIME_FORMAT}
                        minuteStep={30}
                        style={{ width: '100%' }}
                        onChange={handleOnChangePickupTo}
                        onSelect={handleOnChangePickupTo}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="Pickup Email"
                      name="pickupEmail"
                      rules={[{ type: 'email', message: 'Invalid Email' }]}
                    >
                      <Input placeholder=" Pickup Email" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Pickup Address"
                      name="pickupAddress"
                      rules={[
                        {
                          validator: (_, value) => {
                            if (value?.address && value?.latLng?.lat && value?.latLng?.lng) {
                              return Promise.resolve();
                            }

                            if (isEmpty(value?.address)) {
                              return Promise.reject(new Error('Required'));
                            }

                            return Promise.reject(new Error('Invalid'));
                          },
                        },
                      ]}
                    >
                      <AddressInput
                        placeholder="Pickup Address"
                        onSelect={(value) => form.setFieldsValue({ pickupAddress: value })}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Pickup Duration" name="pickupDuration">
                      <Input placeholder="Pickup Duration" suffix="minutes" />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <div className="FormContent">
            <div className="Section">
              <div className="SectionHeader">
                <div className="Left">Delivery</div>
              </div>
              <div className="SectionContent">
                <Row gutter={24}>
                  <Col span={6}>
                    <Form.Item
                      label="Delivery Name"
                      name="deliveryName"
                      rules={[{ required: true, message: 'Required' }]}
                    >
                      <Input placeholder="Delivery Name" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Delivery Phone Number" name="deliveryPhone">
                      <PhoneInput
                        country={'us'}
                        prefix="+"
                        specialLabel=""
                        enableSearch
                        disableSearchIcon
                        onChange={(value, country) => {
                          setCountryDeliveryPhone(country);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Delivery From" name="deliveryTimeStart">
                      <TimePicker
                        placeholder="Delivery From"
                        format={TIME_FORMAT}
                        minuteStep={30}
                        style={{ width: '100%' }}
                        onChange={handleOnChangeDeliveryFrom}
                        onSelect={handleOnChangeDeliveryFrom}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Delivery To" name="deliveryTimeEnd">
                      <TimePicker
                        placeholder="Delivery To"
                        format={TIME_FORMAT}
                        minuteStep={30}
                        style={{ width: '100%' }}
                        onChange={handleOnChangeDeliveryTo}
                        onSelect={handleOnChangeDeliveryTo}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="Delivery Email"
                      name="deliveryEmail"
                      rules={[{ type: 'email', message: 'Invalid Email' }]}
                    >
                      <Input placeholder="Delivery Email" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Delivery Address"
                      name="deliveryAddress"
                      rules={[
                        {
                          validator: (_, value) => {
                            if (
                              (value?.address && value?.latLng?.lat && value?.latLng?.lng) ||
                              (isEmpty(value?.address) && routePlanType !== 'PDP')
                            ) {
                              return Promise.resolve();
                            }

                            if (isEmpty(value?.address) && routePlanType === 'PDP') {
                              return Promise.reject(new Error('Required'));
                            }

                            return Promise.reject(new Error('Invalid'));
                          },
                        },
                      ]}
                    >
                      <AddressInput
                        placeholder="Delivery Address"
                        onSelect={(value) => form.setFieldsValue({ deliveryAddress: value })}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Delivery Duration" name="deliveryDuration">
                      <Input placeholder="Delivery Duration" suffix="minutes" />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <div className="FormContent">
            <div className="Section">
              <div className="SectionHeader">
                <div className="Left">Order Info</div>
              </div>
              <div className="SectionContent">
                <Row gutter={24}>
                  <Col span={6}>
                    <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Required' }]}>
                      <Input placeholder="Name" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Order Ref" name="orderRef">
                      <Input placeholder="Order Ref" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="Delivery Date"
                      name="deliveryDate"
                      rules={[{ required: true, message: 'Required' }]}
                    >
                      <DatePicker placeholder="Delivery Date" format={DAY_FORMAT} style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Load" name="load">
                      <InputNumber placeholder="Load" style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={6}>
                    <Form.Item name="weight" label="Weight">
                      <Input min={1} type="number" suffix="kg" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name="sizeLength" label="Size (LxWxH)">
                      <Input min={1} type="number" suffix="cm" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name="sizeWidth" label=" ">
                      <Input min={1} type="number" suffix="cm" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name="sizeHeight" label=" ">
                      <Input min={1} type="number" suffix="cm" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Description" name="description">
                      <Input.TextArea rows={4} placeholder="Description" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Pickup Note" name="pickupNote">
                      <Input.TextArea rows={4} placeholder="Pickup Note" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Delivery Note" name="deliveryNote">
                      <Input.TextArea rows={4} placeholder="Delivery Note" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Type" name="types">
                      <TagInput tags={types} onChange={(values) => setTypes(values)} />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <div className="FormFooter">
            <div className="Left"></div>
            <div className="Right">
              <Link to={'/orders'}>
                <Button key="cancel">Cancel</Button>
              </Link>
              <Button htmlType="submit" type="primary">
                Create
                <ReactSVG className="icon" src="/icons/submit.svg" />
              </Button>
            </div>
          </div>
        </Form>
      </Spin>
      <Modal
        open={stateModal.open}
        onCancel={() => setStateModal({ ...stateModal, open: false })}
        onOk={() => form2.submit()}
        width={792}
        forceRender
      >
        <Form
          form={form2}
          scrollToFirstError
          layout="vertical"
          hideRequiredMark
          colon={false}
          onFinish={handleOnFinishSelectedHub}
          initialValues={{
            hubId: undefined,
          }}
        >
          <Row>
            <Col span={24}>
              <Form.Item label="Hub" name="hubId" rules={[{ required: true, message: 'Required' }]}>
                <Select
                  size="large"
                  options={hubs.map((hub) => ({
                    label: hub.name,
                    value: hub.id,
                  }))}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default OrderForm;
