import request from '../../utils/request';

export function getFleets(params) {
  return request('/fleets', {
    method: 'GET',
    params: params,
  });
}

export function getFleet(id) {
  return request(`/fleets/${id}`, {
    method: 'GET',
  });
}

export function createFleet(data) {
  return request('/fleets', {
    method: 'POST',
    data,
  });
}

export function updateFleet(data) {
  const id = data.id;
  delete data.id;
  return request(`/fleets/${id}`, {
    method: 'PATCH',
    data,
  });
}
