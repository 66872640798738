import React from 'react';
import { Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { DAY_FORMAT, ORDER_STATUSES_DISPLAY } from '../../../constants';
import './OrderInfoForm.scss';
import TagInput from '../../Elements/TagInput';

const OrderInfoForm = ({ order, types, setTypes }) => {
  return (
    <div className={`OrderInfoForm`}>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Required' }]}>
            <Input placeholder="Name" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Order Ref" name="orderRef">
            <Input placeholder="Order Ref" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="deliveryDate" label="Delivery Date" rules={[{ required: true, message: 'Required' }]}>
            <DatePicker format={DAY_FORMAT} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="load" label="Load">
            <Input min={1} type="number" placeholder="Load" />
          </Form.Item>
        </Col>
        {order && (
          <Col span={6}>
            <Form.Item name="status" label="Status" rules={[{ required: true, message: 'Required' }]}>
              <Select
                options={Object.keys(ORDER_STATUSES_DISPLAY).map((key) => ({
                  value: key,
                  label: ORDER_STATUSES_DISPLAY[key],
                }))}
                placeholder="Order Status"
              />
            </Form.Item>
          </Col>
        )}
        <Col span={4}>
          <Form.Item name="sizeLength" label="Size (LxWxH)">
            <Input min={1} type="number" suffix="cm" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="sizeWidth" label=" ">
            <Input min={1} type="number" suffix="cm" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="sizeHeight" label=" ">
            <Input min={1} type="number" suffix="cm" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="weight" label="Weight">
            <Input min={1} type="number" suffix="kg" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Support Note" name="supportNote">
            <Input.TextArea placeholder="Support Note" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="description" label="Description">
            <Input.TextArea placeholder="Description" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Type" name="types">
            <TagInput tags={types} onChange={(values) => setTypes(values)} />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default OrderInfoForm;
