import React from 'react';
import { connect } from 'react-redux';

import { Navigate } from 'react-router-dom';

export default (Component) => {
  class Container extends React.Component {
    render() {
      const { auth } = this.props;

      return auth?.authenticated ? <Navigate to={'/'} /> : <Component {...this.props} />;
    }
  }
  function mapStateToProps(state) {
    return {
      auth: state.auth,
    };
  }
  return connect(mapStateToProps, {})(Container);
};
