import React, { useMemo } from 'react';
import { AdvancedMarker, InfoWindow, useAdvancedMarkerRef } from '@vis.gl/react-google-maps';
import { VehicleInfoWindow, StopInfoWindow } from './InfoWindow';
import './Marker.scss';
import { STEP_TYPE } from '../../constants';

const Marker = React.memo(({ position, icon, active, onToggle, item, items }) => {
  const [markerRef, marker] = useAdvancedMarkerRef();

  const { headerContent, infoWindow } = useMemo(() => {
    if (item.type === 'start' || item.type === 'end') return VehicleInfoWindow({ item });
    if (item.type === STEP_TYPE.pickup || item.type === STEP_TYPE.delivery) return StopInfoWindow({ item, items });
    return {};
  }, [item]);

  return (
    <>
      <AdvancedMarker
        className={active ? 'm-active' : ''}
        zIndex={active ? 2 : 1}
        position={position}
        onClick={onToggle}
        ref={markerRef}
      >
        {icon}
      </AdvancedMarker>
      {active && (
        <InfoWindow anchor={marker} onCloseClick={onToggle} headerContent={headerContent}>
          {infoWindow}
        </InfoWindow>
      )}
    </>
  );
});

export default Marker;
