import request from '../../utils/request';

export function getDrivers(params) {
  return request('/drivers', {
    method: 'GET',
    params: params,
  });
}

export function getDriver(id) {
  return request(`/drivers/${id}`, {
    method: 'GET',
  });
}

export function createDriver(data) {
  return request('/drivers', {
    method: 'POST',
    data,
  });
}

export function updateDriver(data) {
  const id = data.id;
  delete data.id;
  return request(`/drivers/${id}`, {
    method: 'PATCH',
    data,
  });
}
