import React, { useEffect } from 'react';
import { Form, Input, Button, Row, Col, Select, Spin, DatePicker } from 'antd';
import { ReactSVG } from 'react-svg';
import { DATE_BACKEND_FORMAT, DAY_FORMAT, ORDER_STATUSES_DISPLAY } from '../../../constants';
import dayjs from 'dayjs';
import sortBy from 'lodash/sortBy';

const OrdersFilter = ({ isFetching, query, onSubmit, riders }) => {
  const [form] = Form.useForm();

  const nameField = 'name';
  const nameValue = query[nameField];

  const statusField = 'status';
  const statusValue = query[statusField];

  const deliveryDateFromField = 'deliveryDateFrom';
  const deliveryDateFromValue = query[deliveryDateFromField];

  const deliveryDateToField = 'deliveryDateTo';
  const deliveryDateToValue = query[deliveryDateToField];

  const riderIdField = 'riderId';
  const riderIdValue = query[riderIdField];

  const filterOption = (input, option = null) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    form.setFieldsValue(initialValues());
  }, [query]);

  const initialValues = () => {
    const values = {};
    values[nameField] = nameValue;
    values[statusField] = statusValue || '';
    values['deliveryDate'] = [
      deliveryDateFromValue ? dayjs(deliveryDateFromValue, DATE_BACKEND_FORMAT) : null,
      deliveryDateToValue ? dayjs(deliveryDateToValue, DATE_BACKEND_FORMAT) : null,
    ];
    values[riderIdField] = riderIdValue ? Number(riderIdValue) : '';
    return values;
  };

  const handleSubmit = (values) => {
    const params = {};
    const deliveryDate = values['deliveryDate'] || [];
    params[nameField] = values[nameField] || undefined;
    params[statusField] = values[statusField] || undefined;
    params[deliveryDateFromField] = deliveryDate[0] ? deliveryDate[0].format(DATE_BACKEND_FORMAT) : undefined;
    params[deliveryDateToField] = deliveryDate[1] ? deliveryDate[1].format(DATE_BACKEND_FORMAT) : undefined;
    params[riderIdField] = values[riderIdField] || undefined;
    onSubmit({ ...query, ...params, current: 1 }, true);
  };

  const handleResetButton = () => {
    onSubmit({}, true);
    form.resetFields();
  };

  return (
    <Spin spinning={isFetching}>
      <div className="CommonFilter OrdersFilter">
        <Form
          form={form}
          onFinish={handleSubmit}
          initialValues={initialValues()}
          className="FilterForm OrdersFilterForm"
          layout="vertical"
        >
          <div className="FilterFormHeader">
            <h4>Filter</h4>
            <div className="actions">
              <Button size="small" type="text" onClick={handleResetButton}>
                Clear
                <ReactSVG className="icon" src="/icons/clear_all.svg" />
              </Button>
              <Button className="apply" size="small" type="link" htmlType="submit">
                Apply
                <ReactSVG className="icon" src="/icons/done.svg" />
              </Button>
            </div>
          </div>
          <div className="FilterFormContent">
            <Row gutter={15}>
              <Col xs={{ span: 24 }} md={{ span: 6 }}>
                <Form.Item label="Name" name={nameField}>
                  <Input placeholder="Name" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 6 }}>
                <Form.Item label="Status" name={statusField}>
                  <Select
                    className="select-small"
                    options={[
                      { label: 'ALL', value: '' },
                      ...Object.keys(ORDER_STATUSES_DISPLAY).map((key) => ({
                        label: ORDER_STATUSES_DISPLAY[key],
                        value: key,
                      })),
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 6 }}>
                <Form.Item label="Delivery Date" name="deliveryDate">
                  <DatePicker.RangePicker format={DAY_FORMAT} placeholder={['From', 'To']} allowEmpty={[true, true]} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 6 }}>
                <Form.Item label="Fleet" name={riderIdField}>
                  <Select
                    className="select-small"
                    showSearch
                    filterOption={filterOption}
                    options={[
                      { label: 'ALL', value: '' },
                      ...sortBy(riders || [], (r) => r.user.name).map((r) => ({
                        label: r.user.name,
                        value: r.id,
                      })),
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </Spin>
  );
};

export default OrdersFilter;
