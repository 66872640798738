import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './redux';
import { RouterProvider } from 'react-router-dom';
import AppError from './App/AppError';
import AppMessage from './App/AppMessage';
import router from './App/router';
import { ConfigProvider } from 'antd';
import 'react-phone-input-2/lib/material.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AppError />
      <AppMessage />
      <ConfigProvider
        theme={{
          token: {
            controlHeightLG: 45,
            fontFamily: '"Figtree", sans-serif',
          },
          components: {
            Table: {
              rowSelectedBg: '#e6f4ff',
              rowHoverBg: '#e6f4ff',
              rowSelectedHoverBg: '#e6f4ff',
            },
            DatePicker: {
              cellHoverBg: '#c8dfff',
              activeBg: '#c8dfff',
              cellHoverWithRangeBg: '#c8dfff',
            },
          },
        }}
      >
        <RouterProvider router={router} fallbackElement={<p>Initial Load...</p>} />
      </ConfigProvider>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
