export const APP_NAME = 'sparqle';
export const TOKEN_NAME = process.env.REACT_APP_TOKEN_NAME || '_ovo_session';

export const TIME_FORMAT = 'HH:mm';
export const DAYTIME_FORMAT = 'ddd, MMM Do, YYYY - HH:mm';
export const DAY_FORMAT = 'ddd, MMM Do, YYYY';
export const FULL_DATE_FORMAT = 'ddd, MMM Do, YYYY';
export const DATE_BACKEND_FORMAT = 'YYYY-MM-DD';

export const PAGE_SIZE = 15;

export const PAGE_SIZE_OPTIONS = [PAGE_SIZE, 50, 100];

export const HUB_TAG = {
  CITY_HUB: 'City Hub',
  MICRO_HUB: 'Micro Hub',
  NANO_HUB: 'Nano Hub',
  BIKE_PARK: 'Bike Park',
};

export const FLEET_TAG = {
  BIKE: 'Bike',
  // MODULE: "Module",
  // TRAILER: "Trailer",
  VAN: 'Van',
};

export const FLEET_STATUSES = {
  FUNCTIONAL: 'Functional',
  UNFUNCTIONAL: 'Unfunctional',
  MAINTENANCE: 'Maintenance',
};

export const ORDER_STATUSES_DISPLAY = {
  ERROR: 'Error',
  DRAFT: 'Draft',
  GEOCODING: 'Geocoding',
  UNASSIGNED: 'Unassigned',
  ASSIGNED: 'Assigned',
  UNSCHEDULED: 'Unscheduled',
  GOING_NEXT: 'Going Next',
  SKIPPED: 'Skipped',
  PICKUP_ARRIVED: 'Pickup Arrived',
  PICKUP_FAILED: 'Pickup Failed',
  COLLECTED: 'Collected',
  IN_TRANSIT: 'In Transit',
  DELIVERY_ARRIVED: 'Delivery Arrived',
  DELIVERY_FAILED: 'Delivery Failed',
  COMPLETED: 'Completed',
};

export const ORDER_STATUSES = {
  ERROR: 'ERROR',
  DRAFT: 'DRAFT',
  GEOCODING: 'GEOCODING',
  UNASSIGNED: 'UNASSIGNED',
  ASSIGNED: 'ASSIGNED',
  UNSCHEDULED: 'UNSCHEDULED',
  GOING_NEXT: 'GOING_NEXT',
  SKIPPED: 'SKIPPED',
  PICKUP_ARRIVED: 'PICKUP_ARRIVED',
  PICKUP_FAILED: 'PICKUP_FAILED',
  COLLECTED: 'COLLECTED',
  IN_TRANSIT: 'IN_TRANSIT',
  DELIVERY_ARRIVED: 'DELIVERY_ARRIVED',
  DELIVERY_FAILED: 'DELIVERY_FAILED',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
};

export const STEP_TYPE = {
  pickup: 'PICKUP',
  delivery: 'DELIVERY',
  driver: 'DRIVER',
};

export const COLORS = [
  'ed8f8f',
  'f284a8',
  'c788d2',
  'aa92d6',
  '959ed5',
  '85c3f8',
  '76cef9',
  '75c4bc',

  'cd2929',
  'd21a55',
  '8321a0',
  '532fa8',
  '1c7de1',
  '00a2b9',
  '007e70',

  'ae1a1a',
  '7d1046',
  '411481',
  '2b1a87',
  '0f3e97',
  '044d90',
  '005559',
  '004438',

  '9bd09d',
  'bddd9b',
  'e2eb91',
  'fff392',
  'ffc575',
  'ffa186',
  'd1c5c1',
  'c8d2d7',

  '3b963e',
  '71aa3a',
  'b8c32d',
  'fdd22f',
  'fa8100',
  'f2471c',
  '826358',
  '859aa4',

  '1a531d',
  '2d5e1c',
  '776c17',
  'f37417',
  'e24700',
  'b72f0f',
  '533830',
  '3c5059',
];

export const COLOR_DEFAULT = '7732EF';
