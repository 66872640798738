import React from 'react';
import Icon, { CloseOutlined } from '@ant-design/icons';
import { STEP_TYPE } from '../../constants';

const StepPopupUnassign = ({ step, stepPopover, setStepsHovered, onClickStep }) => {
  return (
    <div className="step-popup">
      <a className="btn-close" onClick={() => setStepsHovered({ [stepPopover]: false })}>
        <CloseOutlined />
      </a>
      <p>
        <strong>[{step.type === STEP_TYPE.pickup ? 'Pickup' : 'Delivery'}]</strong>
      </p>
      <div className="actions">
        {step.routePlanType === 'PDP' && (
          <a
            onClick={() => onClickStep(step.orderId, step.type.toLowerCase() === 'pickup' ? 'delivery' : 'pickup')}
            style={{
              marginLeft: 20,
            }}
          >
            Go to {step.type === STEP_TYPE.pickup ? 'Delivery' : 'Pickup'}
            <Icon
              component={ArrowRight}
              style={{
                width: 10,
                marginLeft: 5,
              }}
            />
          </a>
        )}
      </div>
    </div>
  );
};

export default StepPopupUnassign;

const ArrowRight = () => (
  <svg viewBox="0 0 448 512">
    <path
      fill="currentColor"
      // eslint-disable-next-line max-len
      d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z"
      className=""
    ></path>
  </svg>
);
