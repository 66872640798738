import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Popover } from 'antd';

import StepPopupUnassign from './StepPopupUnassign';
import { STEP_TYPE } from '../../constants';

const UnScheduledSteps = ({
  steps,
  realSteps,
  stepsHovered,
  setStepsHovered,
  onClickStep,
  handleCloseStepPopup,
  setting,
  selectedMultipleSteps,
  multipleStepState,
}) => {
  const newClickStep = (e, step) => {
    if ((e.ctrlKey || e.metaKey) && selectedMultipleSteps) {
      selectedMultipleSteps && selectedMultipleSteps(step, 'unscheduled');
    } else {
      onClickStep(step);
    }
  };
  return (
    <>
      {steps.map((step) => {
        const keyIndex = realSteps.findIndex((rs) => rs.orderId === step.orderId && rs.type === STEP_TYPE.delivery);
        const keyDraggable = `draggable${step.id}${keyIndex}`;
        const stepPopover = `StepPopover-${step.orderId}-${step.type.toLowerCase()}`;
        const isMultipleSelected = multipleStepState?.steps?.find(
          (item) => item.orderId === step.orderId && item.type === step.type && item.driverId === 'unscheduled',
        );
        return (
          <Draggable key={keyDraggable} draggableId={keyDraggable} index={keyIndex}>
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={{
                  zIndex: 99,
                  width: 24,
                  marginLeft: '8px',
                  ...provided.draggableProps.style,
                }}
              >
                <Popover
                  key={keyIndex}
                  trigger="hover"
                  open={stepsHovered[stepPopover] || false}
                  content={
                    <StepPopupUnassign
                      step={step}
                      stepPopover={stepPopover}
                      setStepsHovered={setStepsHovered}
                      onClickStep={onClickStep}
                      setting={setting}
                    />
                  }
                >
                  <div
                    className={`${step.type.toLowerCase() === 'pickup' ? 'unit' : 'unit unit2'} ${stepPopover}`}
                    style={{
                      borderColor: step.color,
                      color: step.color,
                      borderWidth: isMultipleSelected ? '3px' : '1px',
                    }}
                    onClick={(e) => newClickStep(e, step)}
                    onMouseOut={() => handleCloseStepPopup()}
                  >
                    {step.index}
                  </div>
                </Popover>
              </div>
            )}
          </Draggable>
        );
      })}
    </>
  );
};

export default UnScheduledSteps;
