import React from 'react';
import './InfoWindow.scss';
import Icon from '../../icons';
import { ORDER_STATUSES_DISPLAY, STEP_TYPE } from '../../constants';

export const VehicleInfoWindow = ({ item }) => {
  const headerContent = (
    <div className="info-header">
      <Icon type="InfoWindowCar" className="info-icon" color={item.color} />
      <span className="info-id" style={{ color: item.color }}>
        {item?.driver?.name}
      </span>
    </div>
  );

  const infoWindow = (
    <div className="info-window">
      <div className="info-content">
        <div className="info-detail">
          <strong>Start Address</strong> <span>{item.startAddress}</span>
        </div>
        <div className="info-detail">
          <strong>End Location</strong> <span>-</span>
        </div>
        <div className="info-detail">
          <strong>Start Time</strong> <span>{item.shiftStart}</span>
        </div>
        <div className="info-detail">
          <strong>End Time</strong> <span>{item.shiftEnd}</span>
        </div>
      </div>
    </div>
  );
  return { headerContent, infoWindow };
};

export const StopInfoWindow = ({ item, items }) => {
  const headerContent = (
    <div className="info-header">
      <Icon type="InfoWindowCar" className="info-icon" />
      <span className="info-id" style={{ color: item.color }}>
        {item.orderCode}
      </span>
    </div>
  );

  const steps =
    item.type === STEP_TYPE.delivery
      ? items.find((i) => i.type === STEP_TYPE.pickup && i.orderIds?.includes(item.orderId))
      : items.filter((i) => i.type === STEP_TYPE.delivery && item.orderIds?.includes(i.orderId));

  const infoWindow = (
    <div className="info-window">
      <div className="info-content">
        <div className="info-detail">
          <strong>Location</strong> <span>{item?.address}</span>
        </div>
        <div className="info-detail">
          <strong>Duration</strong> <span>{item?.duration}</span>
        </div>
        <div className="info-detail">
          <strong>Delivery Name</strong> <span>{item?.deliveryName}</span>
        </div>
        <div className="info-detail">
          <strong>Arrival Time</strong> <span>{item?.arrivalTime || '-'}</span>
        </div>
        <div className="info-detail">
          <strong>Finish Time</strong> <span>{item?.finishTime || '-'}</span>
        </div>
        <div className="info-detail">
          <strong>Start Time</strong> <span>{item?.startTime}</span>
        </div>
        <div className="info-detail">
          <strong>End Time</strong> <span>{item?.endTime}</span>
        </div>
        <div className="info-detail">
          <strong>Type</strong> <span>{item?.type}</span>
        </div>
        <div className="info-detail">
          <strong>Status</strong> <span>{ORDER_STATUSES_DISPLAY[item?.orderStatus] || item?.orderStatus}</span>
        </div>
        <div className="info-detail">
          {item.type === STEP_TYPE.delivery && steps && (
            <>
              {' '}
              <strong>Pickup: </strong>
              <div className="steps">
                <div className="step" onClick={steps?.onClick}>
                  <div className="pickup" style={{ border: `2px solid ${item.color}`, color: item.color }}>
                    {steps?.index}
                  </div>
                  <b>{steps?.orderCode}</b>
                </div>
              </div>
            </>
          )}
          {item.type === STEP_TYPE.pickup && (
            <>
              {' '}
              <strong>Deliveries: </strong>
              <div className="steps">
                {steps.map((step) => (
                  <div className="step" key={`${step.orderId}_${step.type}`} onClick={step?.onClick}>
                    <div className="delivery" style={{ border: `2px solid ${step.color}`, color: step.color }}>
                      {step?.index}
                    </div>
                    <b>{step.orderCode}</b>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
  return { headerContent, infoWindow };
};
