import { Table } from 'antd';
import React from 'react';
import moment from 'moment';
import { DAYTIME_FORMAT, HUB_TAG } from '../../constants';

export default function HubsTable({ withAction, dataSource, selectedRowKeys, onSelectChange, ...props }) {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: 200,
      className: 'border-right',
    },
    {
      title: 'Tag',
      dataIndex: 'tag',
      key: 'tag',
      width: 70,
      render: (value) => {
        return <span className={'status ' + value.toLowerCase()}>{HUB_TAG[value]}</span>;
      },
    },
    {
      title: 'Start Address',
      dataIndex: 'startAddress',
      key: 'startAddress',
      width: 700,
    },
    {
      title: 'Fleets',
      key: 'countFleets',
      render: (record) => {
        return record?._count?.fleets || 0;
      },
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 250,
      render: (value) => {
        return value ? moment(value).format(DAYTIME_FORMAT) : '';
      },
    },
  ];

  if (withAction) {
    columns.push({
      key: 'action',
      width: 120,
      fixed: 'right',
      render(record) {
        return withAction(record);
      },
      className: 'border-left',
    });
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div className="table-job-orders table-common">
      <Table
        columns={columns}
        rowSelection={rowSelection}
        dataSource={dataSource}
        // bordered
        {...props}
      />
    </div>
  );
}
