import React, { useEffect } from 'react';
import { Button, DatePicker, Form, Select, Spin, TimePicker } from 'antd';
import TagInput from '../Elements/TagInput';
import { DATE_BACKEND_FORMAT, DAY_FORMAT, TIME_FORMAT } from '../../constants';
import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';

const initShift = {
  start: null,
  end: null,
  driverId: null,
  date: null,
  skills: [],
  fleetId: null,
};

const ShiftForm = ({ handleSubmit, handleDelete, action, shift, handleCancel, drivers, fleets, isFetching }) => {
  const [form] = Form.useForm();

  const [skills, setSkills] = React.useState([]);

  const setParams = (values) => {
    const params = {
      ...values,
      start: values?.start?.format(TIME_FORMAT),
      end: values?.end?.format(TIME_FORMAT),
      date: values?.date.format(DATE_BACKEND_FORMAT),
      skills: skills,
    };
    return params;
  };
  const onFinishForm = (values) => {
    handleSubmit && handleSubmit(setParams(values));
  };

  useEffect(() => {
    if (action === 'new') {
      const driver = drivers.find((d) => d.id == shift?.driverId);
      form.setFieldsValue({
        ...initShift,
        date: shift?.date ? dayjs(shift?.date, DATE_BACKEND_FORMAT) : null,
        driverId: shift?.driverId,
        skills: driver?.skills || [],
      });
      setSkills(driver?.skills || []);
    } else if (shift) {
      form.setFieldsValue({
        ...shift,
        start: dayjs(shift?.start, TIME_FORMAT),
        end: dayjs(shift?.end, TIME_FORMAT),
        date: dayjs(shift?.date, DATE_BACKEND_FORMAT),
      });
      setSkills(shift.skills || []);
    }
  }, [action, shift]);

  const resetErrorTime = () => {
    form.setFields([
      {
        name: 'start',
        errors: [],
      },
      {
        name: 'end',
        errors: [],
      },
    ]);
  };

  const handleOnChangeStart = (value) => {
    form.setFieldsValue({ start: value });
    if (isEmpty(value) || isEmpty(form.getFieldValue('end'))) {
      resetErrorTime();
      return;
    }
    if (form.getFieldValue('end') < value) {
      form.setFields([
        {
          name: 'start',
          errors: ['Shift End must be less than Shift Start'],
        },
      ]);
    }
  };

  const handleOnChangeEnd = (value) => {
    form.setFieldsValue({ end: value });
    if (isEmpty(value) || isEmpty(form.getFieldValue('start'))) {
      resetErrorTime();
      return;
    }
    if (form.getFieldValue('start') > value) {
      form.setFields([
        {
          name: 'end',
          errors: ['Shift End must be greater than Shift Start'],
        },
      ]);
    }
  };

  const handleChangeDriver = (id) => {
    const driver = drivers.find((d) => d.id === id);
    form.setFieldsValue({ skills: driver.skills || [] });
    setSkills(driver.skills || []);
  };

  return (
    <Spin spinning={isFetching}>
      <div className="ShiftForm">
        <div className="FormHeader"></div>
        <div className="FormContent">
          <Form
            form={form}
            scrollToFirstError
            layout="vertical"
            requiredMark={false}
            colon={false}
            onFinish={onFinishForm}
          >
            <Form.Item label="Date" name="date" rules={[{ required: true, message: 'Required' }]}>
              <DatePicker placeholder="Date" format={DAY_FORMAT} style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item label="Start" name="start">
              <TimePicker
                placeholder="Start"
                format={TIME_FORMAT}
                style={{ width: '100%' }}
                onChange={handleOnChangeStart}
                onSelect={handleOnChangeStart}
              />
            </Form.Item>

            <Form.Item label="End" name="end">
              <TimePicker
                placeholder="End"
                format={TIME_FORMAT}
                style={{ width: '100%' }}
                onChange={handleOnChangeEnd}
                onSelect={handleOnChangeEnd}
              />
            </Form.Item>

            <Form.Item label="Driver" name="driverId" rules={[{ required: true, message: 'Required' }]}>
              <Select
                placeholder="Select Driver"
                options={drivers.map((driver) => ({
                  value: driver.id,
                  label: driver.name,
                }))}
                onChange={handleChangeDriver}
              />
            </Form.Item>

            <Form.Item label="Fleet" name="fleetId" rules={[{ required: true, message: 'Required' }]}>
              <Select
                placeholder="Select Fleet"
                options={fleets.map((fleet) => ({
                  value: fleet.id,
                  label: fleet.name,
                }))}
              />
            </Form.Item>

            <Form.Item label="Skills" name="skills">
              <TagInput tags={skills} onChange={(values) => setSkills(values)} />
            </Form.Item>
          </Form>
        </div>
        <div className="FormFooter">
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>
          {shift?.id && (
            <Button type="primary" danger block size="large" onClick={handleDelete}>
              Delete
            </Button>
          )}
          <Button type="primary" block size="large" onClick={() => form.submit()}>
            {action === 'new' ? 'Create' : 'Update'}
          </Button>
        </div>
      </div>
    </Spin>
  );
};

export default ShiftForm;
