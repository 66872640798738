import React, { useState, useEffect } from 'react';
import { Input, Menu } from 'antd';
// import { CheckCircleFilled, CloseCircleOutlined } from '@ant-design/icons';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import './AddressInput.scss';

const AddressInput = ({ value, onChange, onSelect, ...props }) => {
  const [address, setAddress] = useState(value?.address ?? '');
  const { suggestions, setValue, clearSuggestions } = usePlacesAutocomplete({
    debounce: 300,
  });

  useEffect(() => {
    clearSuggestions();
    setAddress(value?.address);
  }, [value]);

  const onChangeAddress = (event) => {
    const newAddress = event.target.value;
    setAddress(newAddress);
    setValue(newAddress);
    onChange && onChange({ address: newAddress, latLng: { lat: null, lng: null } });
  };

  const onSelectAddress = async ({ description }) => {
    try {
      getGeocode({ address: description }).then((results) => {
        const { lat, lng } = getLatLng(results[0]);
        setAddress(description);
        onSelect && onSelect({ address: description, latLng: { lat, lng } });
      });
    } catch (error) {
      console.error('Error', error);
    }
  };

  return (
    <div>
      <Input value={address} onChange={onChangeAddress} autoComplete="no" {...props} />
      {suggestions && suggestions?.data.length > 0 && (
        <div className="autocomplete-dropdown-container">
          <Menu mode="vertical">
            {suggestions?.data?.map((suggestion, index) => {
              const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
              const style = {
                cursor: 'pointer',
              };
              return (
                <Menu.Item key={index}>
                  <div
                    className={className}
                    style={style}
                    key={suggestion.description}
                    onClick={() => {
                      onSelectAddress(suggestion);
                      clearSuggestions();
                    }}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                </Menu.Item>
              );
            })}
          </Menu>
        </div>
      )}
    </div>
  );
};

export default AddressInput;
