import React from 'react';
import { Col, Form, Input, Row, TimePicker } from 'antd';
import './PickupForm.scss';
import { TIME_FORMAT } from '../../../constants';
import PhoneInput from 'react-phone-input-2';
import AddressInput from '../../Inputs/AddressInput';
import isEmpty from 'lodash/isEmpty';

const PickupForm = ({ form }) => {
  const handleOnChangePickupFrom = (value) => {
    form.setFieldsValue({ pickupTimeStart: value });
    if (isEmpty(value) || isEmpty(form.getFieldValue('pickupTimeEnd'))) {
      resetErrorPickupTime();
      return;
    }
    if (form.getFieldValue('pickupTimeEnd') < value) {
      form.setFields([
        {
          name: 'pickupTimeStart',
          errors: ['Shift End must be less than Shift Start'],
        },
      ]);
    }
  };

  const handleOnChangePickupTo = (value) => {
    form.setFieldsValue({ pickupTimeEnd: value });
    if (isEmpty(value) || isEmpty(form.getFieldValue('pickupTimeStart'))) {
      resetErrorPickupTime();
      return;
    }
    if (form.getFieldValue('pickupTimeStart') > value) {
      form.setFields([
        {
          name: 'pickupTimeEnd',
          errors: ['Pickup To must be greater than Pickup From'],
        },
      ]);
    }
  };

  const resetErrorPickupTime = () => {
    form.setFields([
      {
        name: 'pickupTimeStart',
        errors: [],
      },
      {
        name: 'pickupTimeEnd',
        errors: [],
      },
    ]);
  };

  return (
    <div className={`PickupForm`}>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item label="Pickup Name" name="pickupName" rules={[{ required: true, message: 'Required' }]}>
            <Input placeholder="Pickup Name" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Pickup Phone Number" name="pickupPhone">
            <PhoneInput country={'us'} specialLabel="" prefix="+" countryCodeEditable enableSearch disableSearchIcon />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Pickup From" name="pickupTimeStart">
            <TimePicker
              placeholder="Pickup From"
              format={TIME_FORMAT}
              minuteStep={30}
              style={{ width: '100%' }}
              onChange={handleOnChangePickupFrom}
              onSelect={handleOnChangePickupFrom}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Pickup To" name="pickupTimeEnd">
            <TimePicker
              placeholder="Pickup To"
              format={TIME_FORMAT}
              minuteStep={30}
              style={{ width: '100%' }}
              onChange={handleOnChangePickupTo}
              onSelect={handleOnChangePickupTo}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Pickup Email" name="pickupEmail" rules={[{ type: 'email', message: 'Invalid Email' }]}>
            <Input placeholder=" Pickup Email" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Pickup Address"
            name="pickupAddress"
            rules={[
              {
                validator: (_, value) => {
                  if (value?.address && value?.latLng?.lat && value?.latLng?.lng) {
                    return Promise.resolve();
                  }

                  if (isEmpty(value?.address)) {
                    return Promise.reject(new Error('Required'));
                  }

                  return Promise.reject(new Error('Invalid'));
                },
              },
            ]}
          >
            <AddressInput
              placeholder="Pickup Address"
              onSelect={(value) => form.setFieldsValue({ pickupAddress: value })}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Pickup Duration" name="pickupDuration">
            <Input placeholder="Pickup Duration" suffix="minutes" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="pickupNote" label="Pickup Note">
            <Input.TextArea placeholder="Note" />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default PickupForm;
