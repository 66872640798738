import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { doDeleteOrders, doGetOrders } from '../modules/orders/stores/thunk';
import socketIOClient from 'socket.io-client';
import { selectAllOrders } from '../modules/orders/stores/slice';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ORDER_STATUSES, PAGE_SIZE, PAGE_SIZE_OPTIONS } from '../constants';
import OrdersTable from '../components/Tables/OrdersTable';
import { Button, Input, message, Popconfirm, Popover } from 'antd';
import { ReactSVG } from 'react-svg';
import queryString from 'query-string';
import OrdersFilter from '../components/Forms/Filters/OrdersFilter';

const socketURI = process.env.REACT_APP_SOCKET_URI || '';
const socket = socketIOClient(socketURI);

const OrdersPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [messageApi, contextHolder] = message.useMessage();

  const { isFetching, metadata, action, status } = useSelector((state) => state.order);
  const orders = useSelector((state) => selectAllOrders(state));

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isActionOpen, setIsActionOpen] = useState(false);

  useEffect(() => {
    setSearchValue(query.q || '');
  }, []);

  const query = Object.fromEntries(searchParams);
  const currentPage = query.current || 1;
  const itemsPerPage = query.pageSize || PAGE_SIZE;
  const pagination = {
    current: currentPage || 1,
    pageSize: itemsPerPage,
    locale: { items_per_page: 'Orders/Page' },
    total: metadata.totalItems || orders.length,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    showSizeChanger: true,
    showTotal: (total) => `${total} Orders`,
  };

  const pushTo = (pushProps) => {
    navigate({
      pathname: pushProps.path || location.pathname,
      search: queryString.stringify(pushProps.query || {}, {
        skipNull: true,
      }),
      state: pushProps.state,
    });
  };

  useEffect(() => {
    dispatch(doGetOrders({ page: currentPage, take: itemsPerPage, ...query }));
  }, []);

  useEffect(() => {
    if (['deleteOrders'].includes(action) && status === 'succeeded')
      dispatch(doGetOrders({ page: currentPage, take: itemsPerPage, ...query }));
  }, [action, status]);

  useEffect(() => {
    socket.connect();
    socket.on('orders', (data) => {
      if (data.event === 'orders.geocoding.completed') {
        dispatch(
          doGetOrders({
            currentPage,
            itemsPerPage,
            ...query,
          }),
        );
      }
    });
    return () => {
      socket.disconnect();
    };
  }, [query]);

  const onSelectChange = (newSelectedRowKeys) => {
    if (newSelectedRowKeys.length === 0 && isActionOpen) setIsActionOpen(false);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleClickDeleteOrders = () => {
    if (isActionOpen) setIsActionOpen(false);
    const orderIdsRemove = orders
      .filter(
        (o) =>
          selectedRowKeys.includes(o.orderCode) &&
          [ORDER_STATUSES.DRAFT, ORDER_STATUSES.GEOCODING, ORDER_STATUSES.ERROR, ORDER_STATUSES.UNASSIGNED].includes(
            o.status,
          ),
      )
      ?.map((o) => o.orderCode);

    if (orderIdsRemove.length !== selectedRowKeys.length) {
      const errorIds = [];
      selectedRowKeys.forEach((orderCode) => {
        if (!orderIdsRemove.includes(orderCode)) errorIds.push(orderCode);
      });
      const orderCodesError = orders.filter((o) => errorIds.includes(o.orderCode))?.map((o) => o.orderCode);
      messageApi.open({
        content: `We're sorry, but we can't delete these orders: ${orderCodesError.join(
          ', ',
        )} at the moment. Orders can only be deleted when they are in the 'Geocoding', 'Draft', 'unassigned' or 'Error' status.`,
        type: 'error',
        style: { marginTop: '52px' },
      });
    } else {
      dispatch(doDeleteOrders({ orderCodes: selectedRowKeys }));
    }
  };

  const contentPopoverAction = () => {
    return (
      <div className="buttons">
        <Popconfirm
          title="Are you sure to delete?"
          onConfirm={() => handleClickDeleteOrders()}
          okText="Yes"
          cancelText="No"
        >
          <Button type="link">Delete</Button>
        </Popconfirm>
      </div>
    );
  };

  const onClickAction = () => {
    selectedRowKeys.length > 0 && setIsActionOpen(!isActionOpen);
  };

  const handleFilter = (params, updatePath) => {
    delete params.pageSizeOptions;
    if (updatePath) {
      pushTo({ query: params });
    }
    const newCurrentPage = Number(params?.current || 1);
    const newItemsPerPage = Number(params?.pageSize) || PAGE_SIZE;
    dispatch(
      doGetOrders({
        page: newCurrentPage,
        take: newItemsPerPage,
        ...params,
      }),
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch && handleSearch();
    }
  };

  const handleTableChange = (params) => {
    const newParams = { ...query, ...params };
    handleFilter(newParams, true);
  };

  const handleChangeSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearch = () => {
    handleFilter({ q: searchValue || '' }, true);
  };

  const withAction = (record) => {
    return (
      <div className="actions">
        <Link to={`/admin/orders/${record.orderCode}`}>
          <Button type="link" className="edit">
            <ReactSVG className="icon" src="/icons/edit.svg" />
          </Button>
        </Link>
        <Button type="link" className="more">
          <ReactSVG className="icon" src="/icons/more.svg" />
        </Button>
      </div>
    );
  };

  return (
    <div className="OrdersPage Content">
      {contextHolder}
      <div className="ContentHeader">
        <div className="HeaderTitle">Orders</div>
        <div className="HeaderToolBar">
          <div className="ToolBarButtons">
            <Link to="/admin/orders/new">
              <Button className="CreateButton ButtonPrimary" type="link" size="small">
                Add Single Order
                <ReactSVG className="icon" src="/icons/plus.svg" />
              </Button>
            </Link>
            <Link to="/admin/orders/upload">
              <Button className="CreateButton ButtonPrimary" type="primary" size="small">
                Upload Order
                <ReactSVG className="icon" src="/icons/upload.svg" />
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <div className="riders-header-table page-header-table">
        <div className="search">
          <Input
            onChange={handleChangeSearch}
            onBlur={handleSearch}
            placeholder="Search"
            onKeyDown={handleKeyDown}
            suffix={<img src="/icons/search.svg" />}
            disabled={isFetching}
            value={searchValue}
          />
        </div>
        <div className="buttons">
          {selectedRowKeys.length > 0 && (
            <Popover
              open={selectedRowKeys?.length > 0 && isActionOpen}
              content={contentPopoverAction}
              overlayClassName="OrdersPagePopoverAction"
              placement="bottom"
            >
              <Button className="FilterButton ButtonFilter" onClick={onClickAction}>
                {selectedRowKeys?.length > 0 && <div className="count">{selectedRowKeys.length}</div>}
                Action <ReactSVG className="icon" src="/icons/arrow_drop_down.svg" />
              </Button>
            </Popover>
          )}

          <Button className="FilterButton ButtonFilter" onClick={() => setIsFilterOpen(!isFilterOpen)}>
            Filter
            <ReactSVG className="icon" src="/icons/filter_list.svg" />
          </Button>
        </div>
      </div>
      {isFilterOpen && <OrdersFilter isFetching={isFetching} query={query} onSubmit={handleFilter} />}
      <div className="ContentMain">
        <OrdersTable
          dataSource={orders}
          pagination={pagination}
          withAction={withAction}
          rowKey="orderCode"
          isFetching={false}
          onSelectChange={onSelectChange}
          onChange={handleTableChange}
        />
      </div>
    </div>
  );
};

export default OrdersPage;
