import React from 'react';
import { STEP_TYPE } from '../../constants';
import { ReactSVG } from 'react-svg';
// import { ReactSVG } from 'react-svg';

const StepContent = ({
  // stop,
  route,
  step,
  indexStep,
  stepPopover,
  onClickStep,
  isLate,
  widthDuration,
  driverId,
  selectedMultipleSteps,
  multipleStepState,
  selectedStep,
}) => {
  const isMultipleSelected = multipleStepState?.steps?.find(
    (item) => item.orderId === step.orderId && item.type === step.type && driverId === item.driverId,
  );
  const color = step.color;

  const style = {
    borderColor: color,
    color: color,
    width: widthDuration,
    minWidth: 'max-content',
    borderWidth: isMultipleSelected ? '3px' : '1px',
    zIndex: 10,
  };

  if (Number(indexStep) >= 10) style.letterSpacing = '-1px';

  if ((step.status === 'COMPLETED' || step.orderStatus === 'COMPLETED') && step.type === STEP_TYPE.delivery) {
    style.color = '#fff';
    style.backgroundColor = color;
  } else if (step.type === STEP_TYPE.pickup) {
    const orderIds = step.orderIds || [];
    const routeSteps = route.steps || [];
    let isCompleted = false;
    routeSteps.forEach((routeStep) => {
      if (isCompleted) return;
      if (routeStep.status !== 'IN_PROGRESS' && orderIds.includes(routeStep.orderId)) isCompleted = true;
    });
    if (isCompleted) {
      style.color = '#fff';
      style.backgroundColor = color;
    }
  }
  if (selectedStep.id === step.id) {
    style.borderWidth = '2px';
    style.zIndex = 10;
  }

  const newClickStep = (e, step) => {
    if ((e.ctrlKey || e.metaKey) && selectedMultipleSteps) {
      selectedMultipleSteps && selectedMultipleSteps(step, driverId);
    } else {
      onClickStep(step);
    }
  };
  return (
    <div
      className={`${step.type.toLowerCase() === 'pickup' ? 'unit' : 'unit unit2'} ${stepPopover}`}
      style={style}
      onClick={(e) => newClickStep(e, step)}
    >
      {step.index}
      {isLate && (
        <span className="anticon">
          <ReactSVG src={`/icons/late.svg`} />
        </span>
      )}
    </div>
  );
};

export default StepContent;
