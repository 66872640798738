import request from '../../utils/request';

export function getSchedulerForms(params) {
  return request('/scheduler-forms', {
    method: 'GET',
    params: params,
  });
}

export function getSchedulerForm(id) {
  return request(`/scheduler-forms/${id}`, {
    method: 'GET',
  });
}

export function createSchedulerForm(data) {
  return request('/scheduler-forms', {
    method: 'POST',
    data,
  });
}

export function updateSchedulerForm(data) {
  const id = data.id;
  delete data.id;
  return request(`/scheduler-forms/${id}`, {
    method: 'PATCH',
    data,
  });
}

export function deleteSchedulerForm(id) {
  return request(`/scheduler-forms/${id}`, {
    method: 'DELETE',
  });
}

export function synchronizeSchedulerForm(data) {
  return request(`/scheduler-forms/synchronize`, {
    method: 'POST',
    data,
  });
}

export function importFromRangeDateSchedulerForm(data) {
  return request(`/scheduler-forms/import-from-range-date`, {
    method: 'POST',
    data,
  });
}
