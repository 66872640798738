import React from 'react';
import { Row, Col } from 'antd';
import withUnAuth from './withUnAuth';
import SignInForm from '../components/Forms/Auth/SignIn';
import { useDispatch } from 'react-redux';
import { doSignIn } from '../modules/auth/stores/thunk';
import { AuthContainer } from '../modules/auth/containers';

const SignInPage = () => {
  const dispatch = useDispatch();

  const onLogin = (params) => {
    dispatch(doSignIn(params));
  };
  return (
    <div className="SignInPage">
      <Row>
        <Col xs={0} sm={0} md={{ span: 12 }}>
          <div
            className="LeftImg"
            style={{
              backgroundImage: `url('/SignIn/bg.png')`,
              backgroundSize: 'cover',
            }}
          />
        </Col>
        <Col xs={24} sm={24} md={{ span: 12 }}>
          <SignInForm onSubmit={onLogin} />
        </Col>
      </Row>
    </div>
  );
};

export default AuthContainer(withUnAuth(SignInPage));
