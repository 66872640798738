import forEach from 'lodash/forEach';
export function capitalize(s) {
  return s[0].toUpperCase() + s.slice(1);
}

export function stringToColorHex(str) {
  // Simple hash function
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Convert the hash to a 6 digit hexadecimal number
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ('00' + value.toString(16)).substr(-2);
  }
  return color;
}

export const convertToMinutes = (time) => {
  if (!time) return 0;
  const timeSplit = time.split(':');
  const hour = timeSplit[0];
  const minute = timeSplit[1];
  return parseInt(hour) * 60 + parseInt(minute);
};

export function convertModelToFormData(val, formData = new FormData(), namespace = '') {
  if (typeof val !== 'undefined' && val !== null) {
    if (val instanceof Date) {
      formData.append(namespace, val.toISOString());
    } else if (val instanceof Array) {
      formData.append(namespace, JSON.stringify(val));
    } else if (typeof val === 'object' && !(val instanceof File)) {
      for (const propertyName in val) {
        // eslint-disable-next-line no-prototype-builtins
        if (val.hasOwnProperty(propertyName)) {
          convertModelToFormData(
            val[propertyName],
            formData,
            namespace ? namespace + '[' + propertyName + ']' : propertyName,
          );
        }
      }
    } else if (val instanceof File) {
      formData.append(namespace, val);
    } else {
      formData.append(namespace, val ? val.toString() : null);
    }
  }
  return formData;
}

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const hexToRgba = (hex, alpha) => {
  // Remove the hash (#) if it's present
  hex = hex.replace(/^#/, '');

  // Check if the hex string is valid
  if (!/^[0-9A-Fa-f]{6}$/.test(hex)) {
    throw new Error('Invalid hex color code');
  }

  // Parse the hex value into its components
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  // Check if the alpha value is within the valid range (0 to 1)
  if (alpha < 0 || alpha > 1) {
    throw new Error('Invalid alpha value');
  }

  // Return the RGBA color as a CSS string
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const convertMinutesToPixel = (minutes, one_hour_px) => {
  // 1 hour = 98px =>  60 minutes = 98px
  if (minutes === 0) return 0;
  return (minutes * one_hour_px) / 60;
};

export const convertToInt = (time) => {
  if (!time) return -1;
  const times = time.split(':');
  return parseInt(times[0]) * 60 + parseInt(times[1]);
};

export async function urlToBase64(url) {
  try {
    const response = await fetch(url);
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error('Error converting URL to base64:', error);
    throw error;
  }
}

export const imageMaker = (child) => {
  return 'data:image/svg+xml;charset=utf-8;base64,' + btoa(unescape(encodeURIComponent(child)));
};

export const hhMmToHours = (timeString) => {
  const arr = timeString.split(':');
  return arr[0] * 1;
};

export const getProgressStatus = (status) => {
  let index = -1;
  if (status === 'ASSIGNED') index = 0;
  if (status === 'SKIPPED') index = 1;
  if (status === 'PICKUP_ARRIVED') index = 1;
  if (status === 'PICKUP_FAILED') index = 1;
  if (status === 'COLLECTED') index = 1;
  if (status === 'IN_TRANSIT') index = 2;
  if (status === 'DELIVERY_ARRIVED') index = 3;
  if (status === 'DELIVERY_FAILED') index = 3;
  if (status === 'COMPLETED') index = 3;
  return index;
};

export const getFirstStartAndLastEnd = (routes) => {
  if (routes.length === 0) return [0, 0];

  const firstRoute = routes[0];
  let returnStart = firstRoute.shiftStart ?? '00:00';
  let returnEnd = firstRoute.shiftEnd;
  let firstStart = convertToMinutes(returnStart);
  let lastEnd = convertToMinutes(returnEnd);

  forEach(routes, (route) => {
    const shiftStart = route.shiftStart ?? '00:00';
    const minuteStartAt = convertToMinutes(shiftStart);
    const minuteEndAt = convertToMinutes(route.shiftEnd);
    if (minuteStartAt < firstStart) {
      returnStart = shiftStart;
      firstStart = minuteStartAt;
    }

    if (lastEnd < minuteEndAt) {
      returnEnd = route.shiftEnd;
      lastEnd = minuteEndAt;
    }
  });
  return [returnStart, returnEnd];
};

export const durationMinutes = (start, end) => {
  if (!start || !end) return 0;
  return convertToMinutes(end) - convertToMinutes(start);
};

export const getListStyle = (dragging, focus, disabled) => {
  const style = {};
  if (dragging) {
    style.border = '1px dashed #b5b5b5';
  }
  if (focus) {
    style.border = '1px dashed #212121';
  }
  if (disabled) {
    style.border = 'none';
    style.cursor = 'no-drop';
  }
  return style;
};

export const getWidthChart = (start, end, one_hour_px) => {
  const duration = durationMinutes(start, end);
  return convertMinutesToPixel(duration, one_hour_px);
};

export const calculatePixelBetweenStops = (stops, route, one_hour_px) => {
  if (!stops || stops?.length === 0) return stops;
  let tmpStop = { ...stops[0] };
  const returnStops = stops.map((stop, index) => {
    if (index === 0) {
      const width = getWidthChart(route.shiftStart, stop.arrivalTime, one_hour_px);
      tmpStop = { ...stop };
      return {
        ...stop,
        index,
        marginLeft: width,
      };
    }
    const width = getWidthChart(tmpStop.finishTime, stop.arrivalTime, one_hour_px);
    tmpStop = { ...stop };
    return {
      ...stop,
      index,
      marginLeft: width,
    };
  });

  return returnStops;
};

export const isDiffTwoArrayStops = (arr1 = [], arr2 = []) => {
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i]?.id !== arr2[i]?.id) return true;
  }
  if (arr1.length !== arr2.length) return true;
  return false;
};

export function ExcelDateToJSDate(serial) {
  const utc_days = Math.floor(serial - 25569);
  const utc_value = utc_days * 86400;
  const date_info = new Date(utc_value * 1000);

  const fractional_day = serial - Math.floor(serial) + 0.0000001;

  let total_seconds = Math.floor(86400 * fractional_day);

  const seconds = total_seconds % 60;

  total_seconds -= seconds;

  const hours = Math.floor(total_seconds / (60 * 60));
  const minutes = Math.floor(total_seconds / 60) % 60;

  return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
}
