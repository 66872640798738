import { combineReducers } from '@reduxjs/toolkit';

import commonReducer from './slice';
import authReducer from '../modules/auth/stores/slice';
import hubReducer from '../modules/hubs/stores/slice';
import FleetReducer from '../modules/fleets/stores/slice';
import OrderReducer from '../modules/orders/stores/slice';
import DriverReducer from '../modules/drivers/stores/slice';
import scheduledReducer from '../modules/scheduler-forms/stores/slice';
import routeReducer from '../modules/routes/stores/slice';

const appReducer = combineReducers({
  common: commonReducer,
  auth: authReducer,
  hub: hubReducer,
  fleet: FleetReducer,
  order: OrderReducer,
  driver: DriverReducer,
  schedulerForm: scheduledReducer,
  route: routeReducer,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
