import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { doCreateHub, doGetHub, doGetHubs, doUpdateHub } from './thunk';

const isActionWithPending = (action) => {
  return action.type && action.type.startsWith('Hubs/') && action.type.endsWith('/pending');
};

const isActionWithFulfilled = (action) => {
  return action.type && action.type.startsWith('Hubs/') && action.type.endsWith('/fulfilled');
};

const isActionWithFailed = (action) => {
  return action.type && action.type.startsWith('Hubs/') && action.type.endsWith('/rejected');
};

const hubsAdapter = createEntityAdapter({
  selectId: (item) => item.id,
});

const hubSlice = createSlice({
  name: 'hub',
  initialState: {
    action: null,
    isFetching: false,
    currentHub: null,
    status: 'idle',
    metadata: {},
    entities: [],
    ids: [],
    item: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetHubs.pending, (state) => {
      state.action = 'getall';
      state.status = 'loading';
    });
    builder.addCase(doGetHubs.fulfilled, (state, action) => {
      const { hubs, pagination } = action.payload;
      state.status = 'succeeded';
      state.metadata = pagination;
      hubsAdapter.setAll(state, hubs);
    });
    builder.addCase(doGetHubs.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doGetHub.pending, (state) => {
      state.action = 'getOne';
      state.status = 'loading';
    });
    builder.addCase(doGetHub.fulfilled, (state, action) => {
      state.status = 'succeeded';
      if (action.payload) {
        state.currentHub = action.payload;
      }
    });
    builder.addCase(doGetHub.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doCreateHub.pending, (state) => {
      state.action = 'create';
      state.status = 'loading';
    });
    builder.addCase(doCreateHub.fulfilled, (state) => {
      state.status = 'succeeded';
    });
    builder.addCase(doCreateHub.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doUpdateHub.pending, (state) => {
      state.action = 'update';
      state.status = 'loading';
    });
    builder.addCase(doUpdateHub.fulfilled, (state) => {
      state.status = 'succeeded';
    });
    builder.addCase(doUpdateHub.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addMatcher(isActionWithPending, (state) => {
      state.isFetching = true;
      state.message = null;
    });
    builder.addMatcher(isActionWithFulfilled, (state) => {
      state.isFetching = false;
    });
    builder.addMatcher(isActionWithFailed, (state) => {
      state.isFetching = false;
      state.message = null;
    });
  },
});

export const {
  selectAll: selectAllHubs,
  selectById: selectHubById,
  selectIds: selectHubIds,
} = hubsAdapter.getSelectors((state) => state.hub);
const { reducer: HubReducer } = hubSlice;
export default HubReducer;
