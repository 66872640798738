import request from '../../utils/request';

export function getOrders(params) {
  return request('/orders', {
    method: 'GET',
    params: params,
  });
}

export function getOrder(id) {
  return request(`/orders/${id}`, {
    method: 'GET',
  });
}

export function createOrder(data) {
  return request('/orders', {
    method: 'POST',
    data,
  });
}

export function changeToUnassigned(data) {
  return request('/orders/change-to-unassigned', {
    method: 'POST',
    data,
  });
}

export function changeToDraft(data) {
  return request('/orders/change-to-draft', {
    method: 'POST',
    data,
  });
}

export function updateOrder(data) {
  const id = data.id;
  delete data.id;
  return request(`/orders/${id}`, {
    method: 'PUT',
    data,
  });
}

export function uploadOrders(data) {
  return request(`/orders/upload`, {
    method: 'POST',
    data,
  });
}

export function deleteOrders(data) {
  return request(`/orders/bulk-delete`, {
    method: 'POST',
    data,
  });
}
