import * as React from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import LayoutAuth from '../Layouts/Auth';
import SignIn from '../Pages/SignIn';
import { Private } from '../modules/auth/containers';
import RoutesPage from '../Pages/Routes';
import LogoutPage from '../Pages/Logout';
import HubsPage from '../Pages/Hubs';
import FleetsPage from '../Pages/Fleets';
import NewOrderPage from '../Pages/NewOrder';
import OrdersPage from '../Pages/Orders';
import UploadOrders from '../Pages/UploadOrders';
import OrderDetailPage from '../Pages/OrderDetail';
import DriversPage from '../Pages/Drivers';
import SchedulerPage from '../Pages/Scheduler';
import RoutesChart from '../Pages/RoutesChart';

const router = createBrowserRouter([
  {
    path: '/login',
    element: (
      <LayoutAuth>
        <SignIn />
      </LayoutAuth>
    ),
  },
  {
    path: '/logout',
    element: <LogoutPage />,
  },
  {
    path: '/admin/routes',
    element: (
      <Private allowedRoles={['admin']}>
        <RoutesPage />
      </Private>
    ),
  },
  {
    path: '/admin/hubs',
    element: (
      <Private allowedRoles={['admin']}>
        <HubsPage />
      </Private>
    ),
  },
  {
    path: '/admin/fleet',
    element: (
      <Private allowedRoles={['admin']}>
        <FleetsPage />
      </Private>
    ),
  },
  {
    path: '/admin/orders',
    element: (
      <Private allowedRoles={['admin']}>
        <OrdersPage />
      </Private>
    ),
  },
  {
    path: '/admin/orders/new',
    element: (
      <Private allowedRoles={['admin']}>
        <NewOrderPage />
      </Private>
    ),
  },
  {
    path: '/admin/orders/upload',
    element: (
      <Private allowedRoles={['admin']}>
        <UploadOrders />
      </Private>
    ),
  },
  {
    path: '/admin/orders/:id',
    element: (
      <Private allowedRoles={['admin']}>
        <OrderDetailPage />
      </Private>
    ),
  },
  {
    path: '/admin/drivers',
    element: (
      <Private allowedRoles={['admin']}>
        <DriversPage />
      </Private>
    ),
  },
  {
    path: '/admin/scheduler',
    element: (
      <Private allowedRoles={['admin']}>
        <SchedulerPage />
      </Private>
    ),
  },
  {
    path: '/admin/route-plans/:type?',
    element: (
      <Private allowedRoles={['admin']}>
        <RoutesChart />
      </Private>
    ),
  },
  {
    path: '/*',
    element: <Navigate to="admin/routes" replace />,
  },
]);

export default router;
