import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { doCreateDriver, doGetDriver, doGetDrivers, doUpdateDriver } from './thunk';

const isActionWithPending = (action) => {
  return action.type && action.type.startsWith('Drivers/') && action.type.endsWith('/pending');
};

const isActionWithFulfilled = (action) => {
  return action.type && action.type.startsWith('Drivers/') && action.type.endsWith('/fulfilled');
};

const isActionWithFailed = (action) => {
  return action.type && action.type.startsWith('Drivers/') && action.type.endsWith('/rejected');
};

const driversAdapter = createEntityAdapter({
  selectId: (item) => item.id,
});

const driverSlice = createSlice({
  name: 'driver',
  initialState: {
    action: null,
    isFetching: false,
    currentDriver: null,
    status: 'idle',
    metadata: {},
    entities: [],
    ids: [],
    item: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetDrivers.pending, (state) => {
      state.action = 'getall';
      state.status = 'loading';
    });
    builder.addCase(doGetDrivers.fulfilled, (state, action) => {
      const { drivers, pagination } = action.payload;
      state.status = 'succeeded';
      state.metadata = pagination;
      driversAdapter.setAll(state, drivers);
    });
    builder.addCase(doGetDrivers.rejected, (state) => {
      state.status = 'failed';
      driversAdapter.setAll(state, []);
    });
    builder.addCase(doGetDriver.pending, (state) => {
      state.action = 'getOne';
      state.status = 'loading';
    });
    builder.addCase(doGetDriver.fulfilled, (state, action) => {
      state.status = 'succeeded';
      if (action.payload) {
        state.currentDriver = action.payload;
      }
    });
    builder.addCase(doGetDriver.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doCreateDriver.pending, (state) => {
      state.action = 'create';
      state.status = 'loading';
    });
    builder.addCase(doCreateDriver.fulfilled, (state) => {
      state.status = 'succeeded';
    });
    builder.addCase(doCreateDriver.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doUpdateDriver.pending, (state) => {
      state.action = 'update';
      state.status = 'loading';
    });
    builder.addCase(doUpdateDriver.fulfilled, (state) => {
      state.status = 'succeeded';
    });
    builder.addCase(doUpdateDriver.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addMatcher(isActionWithPending, (state) => {
      state.isFetching = true;
      state.message = null;
    });
    builder.addMatcher(isActionWithFulfilled, (state) => {
      state.isFetching = false;
    });
    builder.addMatcher(isActionWithFailed, (state) => {
      state.isFetching = false;
      state.message = null;
    });
  },
});

export const {
  selectAll: selectAllDrivers,
  selectById: selectDriverById,
  selectIds: selectDriverIds,
} = driversAdapter.getSelectors((state) => state.driver);
const { reducer: DriverReducer } = driverSlice;
export default DriverReducer;
