import React, { useMemo } from 'react';

import { ReactComponent as MarkerStart } from './markers/start.svg';
import { ReactComponent as MarkerEnd } from './markers/end.svg';
import { ReactComponent as MarkerStop } from './markers/stop.svg';
import { ReactComponent as MarkerPickup } from './markers/pickup.svg';
import { ReactComponent as MarkerDropoff } from './markers/dropoff.svg';

import { ReactComponent as RouteDetailsStart } from './route-details/start.svg';
import { ReactComponent as RouteDetailsEnd } from './route-details/end.svg';

import { ReactComponent as FMTruck } from './fleet-management/truck.svg';
import { ReactComponent as FMStop } from './fleet-management/stop.svg';
import { ReactComponent as FMChecked } from './fleet-management/checked.svg';
import { ReactComponent as FMUnchecked } from './fleet-management/unchecked.svg';
import { ReactComponent as FMExport } from './fleet-management/export.svg';

import { ReactComponent as InfoWindowCar } from './info-window/car.svg';
import { ReactComponent as FileUpload } from './file-upload.svg';

const icons = {
  MarkerStart,
  MarkerEnd,
  MarkerStop,
  RouteDetailsStart,
  RouteDetailsEnd,
  FileUpload,
  InfoWindowCar,
  FMTruck,
  FMStop,
  FMChecked,
  FMUnchecked,
  FMExport,
  MarkerPickup,
  MarkerDropoff,
};

const Icon = ({ type, color, textColor, strokeColor, text, onClick }) => {
  const MarkerIcon = useMemo(() => {
    const IconComponent = icons[type];
    return IconComponent ? (
      <IconComponent
        style={{
          fill: color || undefined,
          stroke: strokeColor || undefined,
        }}
      />
    ) : null;
  }, [type, color]);

  return (
    <div className={`icon-${type}`} onClick={onClick}>
      {MarkerIcon}
      {text !== undefined && <span style={{ color: textColor || undefined }}>{text}</span>}
    </div>
  );
};

export default React.memo(Icon);
