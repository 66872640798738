import { createAsyncThunk } from '@reduxjs/toolkit';
import { setGlobalLoading } from '../../../redux/slice';
import * as api from '../api';

export const doGetDrivers = createAsyncThunk('Drivers/doGetDrivers', async (params, thunkAPI) => {
  try {
    thunkAPI.dispatch(setGlobalLoading(true));
    const { response, error } = await api.getDrivers(params);
    thunkAPI.dispatch(setGlobalLoading(false));
    return error ? thunkAPI.rejectWithValue(error) : response;
  } catch (e) {
    thunkAPI.rejectWithValue(e);
    throw e;
  }
});

export const doGetDriver = createAsyncThunk('Drivers/doGetDriver', async (params, thunkAPI) => {
  try {
    thunkAPI.dispatch(setGlobalLoading(true));
    const { response, error } = await api.getDriver(params.id);
    thunkAPI.dispatch(setGlobalLoading(false));
    return error ? thunkAPI.rejectWithValue(error) : response;
  } catch (e) {
    thunkAPI.rejectWithValue(e);
    throw e;
  }
});

export const doCreateDriver = createAsyncThunk('Drivers/doCreateDriver', async (params, thunkAPI) => {
  try {
    thunkAPI.dispatch(setGlobalLoading(true));
    const { response, error } = await api.createDriver(params);
    thunkAPI.dispatch(setGlobalLoading(false));
    return error ? thunkAPI.rejectWithValue(error) : response;
  } catch (e) {
    thunkAPI.rejectWithValue(e);
    throw e;
  }
});

export const doUpdateDriver = createAsyncThunk('Drivers/doUpdateDriver', async (params, thunkAPI) => {
  try {
    thunkAPI.dispatch(setGlobalLoading(true));
    const { response, error } = await api.updateDriver(params);
    thunkAPI.dispatch(setGlobalLoading(false));
    return error ? thunkAPI.rejectWithValue(error) : response;
  } catch (e) {
    thunkAPI.rejectWithValue(e);
    throw e;
  }
});
