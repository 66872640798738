import { Table } from 'antd';
import React from 'react';
import moment from 'moment';
import { DAYTIME_FORMAT } from '../../constants';

export default function RidersTable({ withAction, dataSource, selectedRowKeys, onSelectChange, ...props }) {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      className: 'border-right',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      key: 'phone',
      width: 200,
      render: (value) => {
        return value?.includes('+') && value[0] !== '0' ? value : value ? `+${value}` : '-';
      },
    },
    {
      title: 'Skills',
      dataIndex: 'skills',
      key: 'skills',
      render: (skills) => {
        return skills?.length > 0 ? (
          <div className="skills">
            {skills.map((skill) => {
              return (
                <span key={skill} className="skill">
                  {skill}
                </span>
              );
            })}
          </div>
        ) : (
          ''
        );
      },
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 250,
      render: (value) => {
        return value ? moment(value).format(DAYTIME_FORMAT) : '';
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      render: (value) => {
        return value ? moment(value).format(DAYTIME_FORMAT) : '';
      },
    },
  ];

  if (withAction) {
    columns.push({
      key: 'action',
      width: 120,
      className: 'border-left',
      fixed: 'right',
      render(record) {
        return withAction(record);
      },
    });
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div className="table-job-orders table-common">
      <Table
        columns={columns}
        rowSelection={rowSelection}
        dataSource={dataSource}
        rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-dark' : 'table-row-light')}
        {...props}
      />
    </div>
  );
}
