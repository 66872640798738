import { createSlice } from '@reduxjs/toolkit';

const isActionWithPending = (action) => {
  return action.type.endsWith('/pending');
};

const isActionWithFulfilled = (action) => {
  return action.type.endsWith('/fulfilled');
};

const isActionWithFailed = (action) => {
  return action.type.endsWith('/rejected');
};

const commonSlice = createSlice({
  name: 'common',
  initialState: {
    globalLoading: false,
    error: {},
    message: null,
    shouldFitbounds: false,
  },
  reducers: {
    setGlobalLoading: (state, action) => {
      const globalLoading = action.payload;
      state.globalLoading = globalLoading;
    },
    setError: (state, action) => {
      const error = action.payload;
      state.error = error;
    },
    setMessage: (state, action) => {
      const message = action.payload;
      state.message = message;
    },
    setShouldFitbounds: (state, action) => {
      const shouldFitbounds = action.payload;
      state.shouldFitbounds = shouldFitbounds;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isActionWithPending, (state) => {
      state.error = {};
      state.message = null;
    });
    builder.addMatcher(isActionWithFulfilled, (state, action) => {
      state.error = {};
      const message = action.payload.message;
      if (message) {
        state.message = message;
      }
    });
    builder.addMatcher(isActionWithFailed, (state, action) => {
      state.error = action.payload;
      state.message = null;
    });
  },
});

const { actions, reducer: commonReducer } = commonSlice;
export const { setGlobalLoading, setError, setMessage, setShouldFitbounds } = actions;

export default commonReducer;
