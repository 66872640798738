import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { Button, Col, Form, Modal, Popover, Row, Spin, Steps, message } from 'antd';
import copy from 'copy-to-clipboard';
import moment from 'moment';
import './OrderDetail.scss';
import { getProgressStatus, imageMaker } from '../utils/common';
import dayjs from 'dayjs';
import { encode } from '@mapbox/polyline';
import { doGetOrder, doUpdateOrder } from '../modules/orders/stores/thunk';
import { DATE_BACKEND_FORMAT, DAY_FORMAT, DAYTIME_FORMAT, ORDER_STATUSES_DISPLAY, TIME_FORMAT } from '../constants';
import { useNavigate, useParams } from 'react-router-dom';
import BreadCrumb from '../modules/breadcrumbs';
import Icon from '../components/Markers/icons/pickup';
import request from '../utils/request';
import OrderInfoForm from '../components/Forms/OrdersDetail/OrderInfoForm';
import PickupForm from '../components/Forms/OrdersDetail/PickupForm';
import DeliveryForm from '../components/Forms/OrdersDetail/DeliveryForm';
import GoogleMap from '../components/Map/GoogleMap';

const typePage = {
  order: {
    type: 'orderInfo',
    title: 'Edit Order Info',
  },
  pickup: {
    type: 'pickupInfo',
    title: 'Edit Pickup',
  },
  delivery: {
    type: 'deliveryInfo',
    title: 'Edit Delivery',
  },
};

const OrderDetailPage = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const order = useSelector((state) => state.order.currentOrder);
  const isFetchingOrder = useSelector((state) => state.order.isFetching);
  const status = useSelector((state) => state.order.status);
  const action = useSelector((state) => state.order.action);

  const isFetching = isFetchingOrder;
  const [messageApi, contextHolder] = message.useMessage();
  const breadCrumbs = [{ text: 'ORDERS', url: '/orders' }, { text: order?.orderCode }];

  const [titleModal, setTitleModal] = useState('');
  const [actionPage, setActionPage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [polylines, setPolylines] = useState([]);
  const [types, setTypes] = useState(order?.types || []);

  useEffect(() => {
    setTypes(order?.types || []);
  }, [order?.types]);

  const logs = order?.logs || [];
  const statusDatetime = (status) => logs.find((l) => l.status === status)?.createdAt;

  const getPolylineAndMarkers = async () => {
    let newPolyline = [];
    const newMarkers = [
      {
        id: `${order.orderCode}-1`,
        content: <img src={imageMaker(Icon())} />,
        latitude: Number(order.pickupAddressLat),
        longitude: Number(order.pickupAddressLng),
        offsetX: 0,
        offsetY: 0,
        className: 'order-marker',
      },
      {
        id: `${order.orderCode}-2`,
        content: <img src="/icons/dropoff.svg" />,
        latitude: Number(order.deliveryAddressLat),
        longitude: Number(order.deliveryAddressLng),
        offsetX: 17,
        offsetY: -10,
        className: 'order-marker',
      },
    ];
    const code = encode([
      [order.pickupAddressLat, order.pickupAddressLng],
      [order.deliveryAddressLat, order.deliveryAddressLng],
    ]);
    const { response } = await request(`/common/polyline/${code}`, {
      method: 'GET',
    });
    if (response?.polyline) {
      newPolyline = [
        {
          id: code,
          polyline: response.polyline,
        },
      ];
    }
    setMarkers(newMarkers);
    setPolylines(newPolyline);
  };

  useEffect(() => {
    if (id) {
      setMarkers([]);
      setPolylines([]);
      dispatch(doGetOrder({ id }));
    }
  }, [id]);

  useEffect(() => {
    if (order?.orderCode === id && order?.pickupAddressLat && order?.deliveryAddressLat) {
      getPolylineAndMarkers();
    }
  }, [order]);

  useEffect(() => {
    if (action === 'getOne' && ['succeeded', 'failed'].includes(status) && !order) {
      navigate('/admin/orders');
    }
  }, [action, status, order]);

  useEffect(() => {
    if (action === 'update' && status === 'succeeded') {
      dispatch(doGetOrder({ id }));
      setIsModalOpen(false);
      setActionPage('');
    }
  }, [action, status]);

  const onClickEditInfo = () => {
    form.setFieldsValue({
      orderType: order.orderType,
      orderRef: order.orderRef,
      status: order.status,
      deliveryDate: dayjs(order.deliveryDate),
      load: order.load,
      createdBy: order.createdBy,
      name: order.name,
      description: order.description,
      sizeLength: order.sizeLength,
      sizeWidth: order.sizeWidth,
      sizeHeight: order.sizeHeight,
      weight: order.weight,
    });
    setActionPage(typePage.order.type);
    setTitleModal(typePage.order.title);
    setIsModalOpen(true);
  };

  const onClickEditPickup = () => {
    form.setFieldsValue({
      pickupName: order.pickupName,
      pickupEmail: order.pickupEmail,
      pickupAddress: {
        address: order.pickupAddress,
        latLng: {
          lat: order.pickupAddressLat,
          lng: order.pickupAddressLng,
        },
      },
      pickupPhone: order.pickupPhone,
      pickupDuration: order.pickupDuration,
      pickupNote: order.pickupNote,
      pickupTimeStart: order.pickupTimeStart ? dayjs(order.pickupTimeStart, TIME_FORMAT) : null,
      pickupTimeEnd: order.pickupTimeEnd ? dayjs(order.pickupTimeEnd, TIME_FORMAT) : null,
    });
    setActionPage(typePage.pickup.type);
    setTitleModal(typePage.pickup.title);
    setIsModalOpen(true);
  };

  const onClickEditDelivery = () => {
    const deliveryTimeStart = order.deliveryTimeStart ? dayjs(order.deliveryTimeStart, TIME_FORMAT) : null;
    const deliveryTimeEnd = order.deliveryTimeEnd ? dayjs(order.deliveryTimeEnd, TIME_FORMAT) : null;
    form.setFieldsValue({
      deliveryName: order.deliveryName,
      deliveryEmail: order.deliveryEmail,
      deliveryPhone: order.deliveryPhone,
      deliveryDuration: order.deliveryDuration,
      deliveryNote: order.deliveryNote,
      deliveryAddress: {
        address: order.deliveryAddress,
        latLng: {
          lat: order.deliveryAddressLat,
          lng: order.deliveryAddressLng,
        },
      },
      deliveryTimeStart,
      deliveryTimeEnd,
    });
    setActionPage(typePage.delivery.type);
    setTitleModal(typePage.delivery.title);
    setIsModalOpen(true);
  };

  const cancelModal = () => {
    setIsModalOpen(false);
    setActionPage(undefined);
  };

  const onFinishForm = (values) => {
    let params = {};
    if (actionPage === typePage.order.type) {
      params = {
        sizeWidth: values.sizeWidth ? Number(values.sizeWidth) : null,
        sizeLength: values.sizeLength ? Number(values.sizeLength) : null,
        sizeHeight: values.sizeHeight ? Number(values.sizeHeight) : null,
        weight: values.weight ? Number(values.weight) : null,
        orderRef: values.orderRef,
        deliveryDate: values.deliveryDate.format(DATE_BACKEND_FORMAT),
        load: values.load ? Number(values.load) : undefined,
        name: values.name,
        status: values.status,
        description: values.description,
        supportNote: values.supportNote,
        types: types,
      };
    }
    if (actionPage === typePage.pickup.type) {
      params = {
        pickupName: values.pickupName,
        pickupAddress: values?.pickupAddress?.address,
        pickupAddressLat: Number(values?.pickupAddress?.latLng?.lat),
        pickupAddressLng: Number(values?.pickupAddress?.latLng?.lng),
        pickupPhone: values.pickupPhone,
        pickupEmail: values.pickupEmail,
        pickupNote: values.pickupNote,
        pickupDuration: values.pickupDuration ? Number(values.pickupDuration) : undefined,
        pickupTimeStart: values.pickupTimeStart ? values.pickupTimeStart.format(TIME_FORMAT) : undefined,
        pickupTimeEnd: values.pickupTimeEnd ? values.pickupTimeEnd.format(TIME_FORMAT) : undefined,
      };
    }
    if (actionPage === typePage.delivery.type) {
      params = {
        deliveryName: values.deliveryName,
        deliveryAddress: values?.deliveryAddress?.address,
        deliveryAddressLat: Number(values?.deliveryAddress?.latLng?.lat),
        deliveryAddressLng: Number(values?.deliveryAddress?.latLng?.lng),
        deliveryPhone: values.deliveryPhone,
        deliveryEmail: values.deliveryEmail,
        deliveryNote: values.deliveryNote,
        deliveryDuration: values.deliveryDuration ? Number(values.deliveryDuration) : undefined,
        deliveryTimeStart: values.deliveryTimeStart ? values.deliveryTimeStart.format(TIME_FORMAT) : undefined,
        deliveryTimeEnd: values.deliveryTimeEnd ? values.deliveryTimeEnd.format(TIME_FORMAT) : undefined,
      };
    }
    dispatch(doUpdateOrder({ id, ...params }));
  };

  const goRoutePlanDetail = (order, type) => {
    navigate('/routes/' + order.routePlan?.slug + '?orderCode=' + order.id + '&type=' + type);
  };

  const onValuesChange = (changedValues, allValues) => {
    console.log({ changedValues, allValues });
  };

  const onClickCopy = (value) => {
    copy(value);
    messageApi.open({
      type: 'success',
      style: {
        marginTop: '68px',
      },
      content: 'Copied',
    });
  };

  const content = (
    <div className="actions">
      {/* <Button
        type="link"
        onClick={() =>
          onClickCopy(process.env.REACT_APP_URI + "orders/" + order.orderCode)
        }
      >
        <ReactSVG className="icon" src="/icons/assignment.svg" /> Share Order
        Details
      </Button>
      <Button
        type="link"
        onClick={() =>
          onClickCopy(process.env.REACT_APP_TRACKING_URL + order.orderCode)
        }
      >
        <ReactSVG className="icon" src="/icons/attachment.svg" /> Share Track
        and Trace URL
      </Button> */}
    </div>
  );

  if (!order) return <Spin style={{ width: '100vw', height: '100vh' }} spinning={isFetching}></Spin>;

  const itemsStatus = [
    {
      title: <div className="status order assigned">Assigned</div>,
      description: statusDatetime('ASSIGNED') && moment(statusDatetime('ASSIGNED')).format(DAYTIME_FORMAT),
    },
  ];

  if (order?.status === ORDER_STATUSES_DISPLAY.SKIPPED) {
    itemsStatus.push({
      title: <div className="status order skipped">Skipped</div>,
      description: statusDatetime('SKIPPED') && moment(statusDatetime('SKIPPED')).format(DAYTIME_FORMAT),
    });
  } else if (order?.status === 'PICKUP_ARRIVED') {
    itemsStatus.push({
      title: (
        <div className={'status order ' + order?.status?.toLowerCase()}>{ORDER_STATUSES_DISPLAY[order?.status]}</div>
      ),
      description: statusDatetime('PICKUP_ARRIVED') && moment(statusDatetime('PICKUP_ARRIVED')).format(DAYTIME_FORMAT),
    });
  } else if (order?.status === 'PICKUP_FAILED') {
    itemsStatus.push({
      title: (
        <div className={'status order ' + order?.status?.toLowerCase()}>{ORDER_STATUSES_DISPLAY[order?.status]}</div>
      ),
      description: statusDatetime('PICKUP_ARRIVED') && moment(statusDatetime('PICKUP_ARRIVED')).format(DAYTIME_FORMAT),
    });
  }

  itemsStatus.push({
    title: <div className="status order collected">Collected</div>,
    description: statusDatetime('COLLECTED') && moment(statusDatetime('COLLECTED')).format(DAYTIME_FORMAT),
  });

  itemsStatus.push({
    title: <div className="status order in_transit">In Transit</div>,
    description: statusDatetime('IN_TRANSIT') && moment(statusDatetime('IN_TRANSIT')).format(DAYTIME_FORMAT),
  });

  if (order?.status === 'DELIVERY_FAILED') {
    itemsStatus.push({
      title: (
        <div className={'status order ' + order?.status?.toLowerCase()}>
          {ORDER_STATUSES_DISPLAY[order?.status?.replace('_', ' ')]}
        </div>
      ),
      description:
        statusDatetime('DELIVERY_FAILED') && moment(statusDatetime('DELIVERY_FAILED')).format(DAYTIME_FORMAT),
    });
  } else if (order?.status === 'DELIVERY_ARRIVED') {
    itemsStatus.push({
      title: (
        <div className={'status order ' + order?.status?.toLowerCase()}>
          {ORDER_STATUSES_DISPLAY[order?.status?.replace('_', ' ')]}
        </div>
      ),
      description:
        statusDatetime('DELIVERY_ARRIVED') && moment(statusDatetime('DELIVERY_ARRIVED')).format(DAYTIME_FORMAT),
    });
  }

  itemsStatus.push({
    title: <div className="status order completed">Completed</div>,
    description: statusDatetime('COMPLETED') && moment(statusDatetime('COMPLETED')).format(DAYTIME_FORMAT),
  });

  return (
    <Spin spinning={isFetching}>
      <div className="OrderDetailPage Content">
        {contextHolder}
        <BreadCrumb breadCrumbs={breadCrumbs} />
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <div className="OrderInfo white border-bottom">
              <div className="OrderInfoHeader">
                <div className="left">
                  <div className="orderId" onClick={() => onClickCopy(order.orderId)}>
                    {order.orderCode}
                    <img src="/icons/file_copy.svg" />
                  </div>
                </div>
                <div className="right">
                  <div className="createdInfo">
                    Created {!!order.createdBy && ' From '}
                    {!!order.createdBy && <b>{order.createdBy}</b>} at{' '}
                    <b>{moment(order.createdAt).format(DAYTIME_FORMAT)}</b>
                  </div>
                  <div className="headerActions">
                    <Button className="ButtonPrimary orderButton" type="primary" size="small" onClick={onClickEditInfo}>
                      Edit
                      <ReactSVG className="icon" src="/icons/edit.svg" />
                    </Button>
                    <Popover
                      overlayClassName="share-content-order"
                      placement="bottomLeft"
                      content={content}
                      trigger="click"
                    >
                      <Button className="ButtonWhite orderButton" size="small">
                        <ReactSVG className="icon upload" src="/icons/share.svg" />
                      </Button>
                    </Popover>
                  </div>
                </div>
              </div>
              <div className="OrderInfoContent">
                <Row gutter={[24, 16]}>
                  <Col span={6}>
                    <label>Order Ref</label>
                    <div className="order-content hasCopy" onClick={() => onClickCopy(order.orderRef)}>
                      {order.orderRef}
                    </div>
                  </Col>
                  <Col span={6}>
                    <label>Delivery Date</label>
                    <div className="order-content">{moment(order.deliveryDate).format(DAY_FORMAT)}</div>
                  </Col>
                  <Col span={6}>
                    <label>Load</label>
                    <div className="order-content">{order.load}</div>
                  </Col>
                  <Col span={6}>
                    <label>Types</label>
                    <div className="order-content">{order.types ? order.types.join(', ') : ''}</div>
                  </Col>
                  <Col span={6}>
                    <label>Name</label>
                    <div className="order-content">{order.name}</div>
                  </Col>
                  <Col span={6}>
                    <label>Description</label>
                    <div className="order-content">{order.description || '-'}</div>
                  </Col>
                  <Col span={6}>
                    <label>Size (LxWxH)</label>
                    <div className="order-content">
                      {order.sizeWidth ? order.sizeWidth + ' x ' : ''}{' '}
                      {order.sizeLength ? order.sizeLength + ' x ' : ''} {order.sizeHeight}
                    </div>
                  </Col>
                  <Col span={6}>
                    <label>Weight</label>
                    <div className="order-content">{order.weight}</div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="statuses-log white border-bottom">
              <div className="header">
                <div className="Info Rider">
                  <label>Rider: </label>
                  <div className="Text">{order.rider?.user?.name || 'Unassigned'}</div>
                </div>
                <div className="button-history">
                  {/* <Button size="small">
                    History Log
                    <ReactSVG className="icon" src="/icons/history.svg" />
                  </Button> */}
                </div>
              </div>
              <div className="statuses">
                <Steps current={getProgressStatus(order.status)} labelPlacement="vertical" items={itemsStatus} />
              </div>
            </div>
            <div className="super-note white">
              <p>
                <b>Support Note:</b> -
              </p>
            </div>
          </Col>
          <Col span={12}>
            <div className="PartnerInfo Pickup white">
              <Row gutter={[24, 16]}>
                <Col span={12}>
                  <div className="header left">
                    <div className="text">
                      <h5>{order?.pickupName || '-'}</h5>
                      <span>{order?.pickupEmail || '-'}</span>
                    </div>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="actions header right">
                    <Button type="link" size="small" onClick={onClickEditPickup}>
                      Edit
                      <ReactSVG className="icon" src="/icons/edit.svg" />
                    </Button>
                    {order.routePlanId && (
                      <Button
                        type="link"
                        size="small"
                        disabled={!order.routePlanId}
                        onClick={() => goRoutePlanDetail(order, STEP_TYPE.pickup)}
                      >
                        View on Route Plan
                        <ReactSVG className="icon" src="/icons/routePlan.svg" />
                      </Button>
                    )}
                  </div>
                </Col>
                <Col span={24}>
                  <label>Address</label>
                  <div className="order-content hasCopy" onClick={() => onClickCopy(order.pickupAddress)}>
                    {order.pickupAddress}
                    <img src="/icons/file_copy.svg" />
                  </div>
                </Col>
                <Col span={8}>
                  <label>Phone Number</label>
                  <div className="order-content">{order.pickupPhone}</div>
                </Col>
                <Col span={8}>
                  <label>Pickup Time Window</label>
                  <div className="order-content">
                    {order.pickupTimeStart || ''} - {order.pickupTimeEnd || ''}
                  </div>
                </Col>
                <Col span={8}>
                  <div>
                    <label>Duration</label>
                    <div className="order-content">
                      {order.pickupDuration
                        ? order.pickupDuration + (order.pickupDuration > 1 ? ' minutes' : ' minute')
                        : ''}
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  <label>Note</label>
                  <div className="order-content">{order.pickupNote || '-'}</div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={12}>
            <div className="PartnerInfo Delivery white">
              <Row gutter={[24, 16]}>
                <Col span={12}>
                  <div className="header left">
                    <div className="text">
                      <h5>{order?.deliveryName || '-'}</h5>
                      <span>{order?.deliveryEmail || '-'}</span>
                    </div>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="actions header right">
                    {!order.routePlanId && (
                      <Button type="link" size="small" onClick={onClickEditDelivery}>
                        Edit
                        <ReactSVG className="icon" src="/icons/edit.svg" />
                      </Button>
                    )}
                    {order.routePlanId && (
                      <Button
                        type="link"
                        size="small"
                        disabled={!order.routePlanId}
                        onClick={() => goRoutePlanDetail(order, STEP_TYPE.delivery)}
                      >
                        View on Route Plan
                        <ReactSVG className="icon" src="/icons/routePlan.svg" />
                      </Button>
                    )}
                  </div>
                </Col>
                <Col span={24}>
                  <label>Address</label>
                  <div className="order-content hasCopy" onClick={() => onClickCopy(order.deliveryAddress)}>
                    {order.deliveryAddress}
                    <img src="/icons/file_copy.svg" />
                  </div>
                </Col>
                <Col span={8}>
                  <label>Phone Number</label>
                  <div className="order-content">{order.deliveryPhone}</div>
                </Col>
                <Col span={8}>
                  <label>Delivery Time Window</label>
                  <div className="order-content">
                    {order.deliveryTimeStart || ''} - {order.deliveryTimeEnd || ''}
                  </div>
                </Col>
                <Col span={8}>
                  <div>
                    <label>Duration</label>
                    <div className="order-content">
                      {order.deliveryDuration
                        ? order.deliveryDuration + (order.deliveryDuration > 1 ? ' minutes' : ' minute')
                        : '-'}
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  <label>Note</label>
                  <div className="order-content">{order.deliveryNote || '-'}</div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={12}>
            <div className="wrapper-map">
              <GoogleMap markers={markers} polylines={polylines} className="mapbox-order-detail" />
            </div>
          </Col>
          <Col span={12}>
            <div className="DeliveryDetail white">
              <Row>
                <Col span={24}>
                  <div className="wrapper border-bottom">
                    <div className="title">Delivery Status</div>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="wrapper">
                    <div className="title">POD</div>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="wrapper border-left">
                    <div className="title">Signature</div>
                    <div
                      style={{
                        marginTop: '16px',
                        background: '#F4F4F4',
                        height: '228px',
                        width: '100%',
                      }}
                    ></div>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="wrapper border-top">
                    <div className="title">Rider Note</div>
                    <div className="content">-</div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <Modal
        width={1128}
        closeIcon={false}
        title={titleModal || 'Edit Order Info'}
        open={isModalOpen}
        forceRender
        footer={false}
        onCancel={cancelModal}
      >
        <Spin spinning={isFetching}>
          <div className="OrderDetailModal">
            <Form
              form={form}
              scrollToFirstError
              layout="vertical"
              requiredMark={false}
              colon={false}
              className="FormContent"
              onFinish={onFinishForm}
              onValuesChange={onValuesChange}
            >
              {actionPage === typePage.order.type && <OrderInfoForm order={order} types={types} setTypes={setTypes} />}
              {actionPage === typePage.pickup.type && <PickupForm form={form} />}
              {actionPage === typePage.delivery.type && <DeliveryForm form={form} />}
              <div className="FormFooter">
                <Button className="ButtonCancel" onClick={cancelModal}>
                  Cancel
                </Button>
                <Button className="CreateButton ButtonPrimary" type="primary" htmlType="submit">
                  Update
                  <ReactSVG className="icon" src="/icons/done.svg" />
                </Button>
              </div>
            </Form>
          </div>
        </Spin>
      </Modal>
    </Spin>
  );
};

export default OrderDetailPage;
