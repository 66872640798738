import React from 'react';
import { Row, Col } from 'antd';

import { useWindowSize } from '../utils/layout';
import './withUnAuth.scss';

export default function (Component) {
  const UnAuthPage = (props) => {
    const [, height] = useWindowSize();
    return (
      <div className="UnAuthPage">
        <Row style={{ minHeight: height }}>
          <Col xs={24} sm={{ span: 20, offset: 2 }} md={{ span: 20, offset: 2 }} lg={{ span: 14, offset: 5 }}>
            <Component {...props} />
          </Col>
        </Row>
      </div>
    );
  };
  return UnAuthPage;
}
