import React, { useEffect } from 'react';
import { Button, Form, Input, Spin } from 'antd';
import PhoneInput from 'react-phone-input-2';
import TagInput from '../Elements/TagInput';
const RiderForm = ({ handleSubmit, action, rider, handleCancel, isFetching }) => {
  const [form] = Form.useForm();

  const [skills, setSkills] = React.useState([]);

  const setParams = (values) => {
    const params = { ...values, skills };
    return params;
  };
  const onFinishForm = (values) => {
    handleSubmit && handleSubmit(setParams(values));
  };

  useEffect(() => {
    if (action === 'new') {
      form.setFieldsValue({ name: null, email: null });
    } else if (rider) {
      form.setFieldsValue({
        ...rider,
      });
      setSkills(rider.skills || []);
    }
  }, [action, rider]);

  return (
    <Spin spinning={isFetching}>
      <div className="RiderForm">
        <div className="FormHeader"></div>
        <div className="FormContent">
          <Form
            form={form}
            scrollToFirstError
            layout="vertical"
            requiredMark={false}
            colon={false}
            onFinish={onFinishForm}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: 'Required' },
                { type: 'email', message: 'Invalid Email' },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item label="Password" name="password" rules={[{ required: !rider, message: 'Required' }]}>
              <Input.Password placeholder="Password" autoComplete="new-password" />
            </Form.Item>
            <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Required' }]}>
              <Input placeholder="Name" />
            </Form.Item>
            <Form.Item label="Phone Number" name="phone">
              <PhoneInput
                country={'us'}
                prefix="+"
                specialLabel=""
                countryCodeEditable
                enableSearch
                disableSearchIcon
              />
            </Form.Item>
            <Form.Item label="Skills" name="skills">
              <TagInput tags={skills} onChange={(values) => setSkills(values)} />
            </Form.Item>
          </Form>
        </div>
        <div className="FormFooter">
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="primary" block size="large" onClick={() => form.submit()}>
            {action === 'new' ? 'Create' : 'Update'}
          </Button>
        </div>
      </div>
    </Spin>
  );
};

export default RiderForm;
