import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import './RoutePlanLeft.scss';
import { Menu } from 'antd';
import { ReactSVG } from 'react-svg';
import moment from 'moment';
import { DATE_BACKEND_FORMAT } from '../../constants';

const RoutePlanLeft = ({ deliveryDate, orders }) => {
  const date = moment(deliveryDate?.format(DATE_BACKEND_FORMAT), DATE_BACKEND_FORMAT);
  const location = useLocation();
  const items = [
    {
      label: (
        <Link to={`/admin/route-plans/orders?date=${date.format(DATE_BACKEND_FORMAT)}`}>
          <div className="Title">Orders</div>
          <div className="Count">({orders?.length || 0})</div>
          <ReactSVG className="icon" src="/icons/add_circle.svg" />
        </Link>
      ),
      key: 'route-plans/orders',
    },
  ];

  let selectedKey = location.pathname;

  if (selectedKey.includes('orders') && selectedKey.includes('route-plans')) {
    selectedKey = 'route-plans/orders';
  }

  return (
    <div className="RoutePlanLeft">
      <div className="RoutePlanLeft__Top">
        <Link to={`/admin/route-plans?date=${date.format(DATE_BACKEND_FORMAT)}`}>
          <div className="RoutePlanLeft__Top__Title">{date.format('ddd')}</div>
          <div className="RoutePlanLeft__Top__Title">{date.format('MMM')}</div>
          <div className="RoutePlanLeft__Top__Sub">{date.format('DD')}</div>
        </Link>
      </div>
      <Menu className="RoutePlanMenu" items={items} selectedKeys={[selectedKey]} />
    </div>
  );
};

export default RoutePlanLeft;
