import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllHubs } from '../modules/hubs/stores/slice';
import { doCreateHub, doGetHub, doGetHubs, doUpdateHub } from '../modules/hubs/stores/thunk';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { FLEET_STATUSES, FLEET_TAG, PAGE_SIZE, PAGE_SIZE_OPTIONS } from '../constants';
import { Button, Input, Menu, Modal, Spin } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { ReactSVG } from 'react-svg';
import queryString from 'query-string';
import HubForm from '../components/Forms/HubForm';
import HubsTable from '../components/Tables/HubsTable';
import './Hubs.scss';
import FleetsTable from '../components/Tables/FleetsTable';
import GoogleMap from '../components/Map/GoogleMap';

const initStateModal = {
  open: false,
  hub: null,
  action: 'ide',
};

const itemsMenu = [
  {
    label: <div className="MenuItem">List View</div>,
    key: 'list_view',
  },
  {
    label: <div className="MenuItem">Map View</div>,
    key: 'map_view',
  },
];

const fleetsColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
    width: 200,
  },
  {
    title: 'Tag',
    dataIndex: 'tag',
    key: 'tag',
    width: 70,
    render: (value) => {
      return <span className={'status ' + value.toLowerCase()}>{FLEET_TAG[value]}</span>;
    },
  },
  {
    title: 'Access Code',
    dataIndex: 'accessCode',
    key: 'accessCode',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    fixed: 'right',
    width: 200,
    render: (value) => {
      return <span className={'status fleet ' + value.toLowerCase()}>{FLEET_STATUSES[value]}</span>;
    },
  },
];

const HubsPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { isFetching, action, status, metadata, currentHub } = useSelector((state) => state.hub);
  const hubs = useSelector((state) => selectAllHubs(state));

  const [viewMode, setViewMode] = useState('list_view');
  const [searchValue, setSearchValue] = useState('');
  const [searchParams] = useSearchParams();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [stateModal, setStateModal] = useState(initStateModal);
  const [selectedHubId, setSelectedHubId] = useState();

  const query = Object.fromEntries(searchParams);
  const currentPage = query.current || 1;
  const itemsPerPage = query.pageSize || PAGE_SIZE;
  const pagination = {
    current: currentPage || 1,
    pageSize: itemsPerPage,
    locale: { items_per_page: 'Hubs/Page' },
    total: metadata.totalItems || hubs.length,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    showSizeChanger: true,
    showTotal: (total) => `${total} Hubs`,
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onSelectHub = (id) => {
    if (selectedHubId === id) {
      handleCancelSelectedHub();
    } else {
      setSelectedHubId(id);
    }
  };

  const handleCancelSelectedHub = () => {
    setSelectedHubId(undefined);
  };

  useEffect(() => {
    if (['create', 'update'].includes(action) && status === 'succeeded') {
      if (viewMode === 'list_view') {
        dispatch(doGetHubs({ page: currentPage, take: itemsPerPage, ...query }));
      } else {
        dispatch(doGetHubs({ page: 1, take: 100000 }));
      }
      handleCancelModal();
    }
  }, [isFetching]);

  useEffect(() => {
    if (hubs.length > 0) {
      setMarkers(
        hubs.map((hub) => {
          return {
            id: hub.id,
            content:
              selectedHubId === hub.id ? (
                <img src="/icons/hub-detail-active.svg" />
              ) : (
                <img src="/icons/hub-detail.svg" />
              ),
            latitude: Number(hub.startAddressLat),
            longitude: Number(hub.startAddressLng),
            offsetX: 0,
            offsetY: 0,
            className: 'hub-marker',
            onClick: () => onSelectHub(hub.id),
          };
        }),
      );
    } else {
      setMarkers([]);
    }
  }, [hubs, selectedHubId]);

  useEffect(() => {
    if (selectedHubId) {
      dispatch(doGetHub({ id: selectedHubId }));
    }
  }, [selectedHubId]);

  useEffect(() => {
    setSearchValue(query.q || '');
  }, []);

  useEffect(() => {
    if (viewMode === 'list_view') {
      dispatch(doGetHubs({ page: currentPage, take: itemsPerPage, ...query }));
    } else {
      dispatch(doGetHubs({ page: 1, take: 100000 }));
    }
  }, [viewMode]);

  const handleCancelModal = () => {
    setStateModal(initStateModal);
  };

  const pushTo = (pushProps) => {
    navigate({
      pathname: pushProps.path || location.pathname,
      search: queryString.stringify(pushProps.query || {}, {
        skipNull: true,
      }),
      state: pushProps.state,
    });
  };

  const handleSubmitModal = (data) => {
    if (stateModal.action === 'new') {
      dispatch(doCreateHub(data));
    } else {
      data.id = stateModal.hub.id;
      dispatch(doUpdateHub(data));
    }
  };

  const handleFilter = (params, updatePath) => {
    delete params.pageSizeOptions;
    if (updatePath) {
      pushTo({ query: params });
    }
    const newCurrentPage = Number(params?.current || 1);
    const newItemsPerPage = Number(params?.pageSize) || PAGE_SIZE;
    dispatch(
      doGetHubs({
        page: newCurrentPage,
        take: newItemsPerPage,
        ...params,
      }),
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch && handleSearch();
    }
  };

  const handleChangeSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearch = () => {
    handleFilter({ q: searchValue || '' }, true);
  };

  const onClickNew = () => {
    setStateModal({
      ...stateModal,
      open: true,
      hub: null,
      action: 'new',
    });
  };

  const onClickEdit = (record) => {
    setStateModal({
      ...stateModal,
      open: true,
      hub: record,
      action: 'edit',
    });
  };

  const handleTableChange = (params) => {
    const newParams = { ...query, ...params };
    handleFilter(newParams, true);
  };

  const withAction = (record) => {
    return (
      <div className="actions">
        <Button type="link" className="edit" onClick={() => onClickEdit(record)}>
          <ReactSVG className="icon" src="/icons/edit.svg" />
        </Button>
        <Button type="link" className="more">
          <ReactSVG className="icon" src="/icons/more.svg" />
        </Button>
      </div>
    );
  };

  return (
    <Spin spinning={isFetching}>
      <div className="HubsPage Content">
        <div className="ContentHeader">
          <div className="HeaderTitle">Hubs</div>
          <div className="HeaderToolBar">
            <div className="ToolBarButtons">
              <Button className="CreateButton ButtonPrimary" type="primary" size="small" onClick={onClickNew}>
                Create New Hub
                <ReactSVG className="icon" src="/icons/plus.svg" />
              </Button>
            </div>
          </div>
        </div>
        <div className="ContentSelect">
          <Menu selectedKeys={[viewMode]} mode="horizontal" onClick={({ key }) => setViewMode(key)} items={itemsMenu} />
        </div>
        {viewMode === 'list_view' && (
          <>
            <div className="page-header-table">
              <div className="search">
                <Input
                  onChange={handleChangeSearch}
                  onBlur={handleSearch}
                  placeholder="Search"
                  onKeyDown={handleKeyDown}
                  suffix={<img src="/icons/search.svg" />}
                  disabled={isFetching}
                  value={searchValue}
                />
              </div>
              <div className="buttons">
                <Button className="FilterButton ButtonFilter">
                  {selectedRowKeys?.length > 0 && <div className="count">{selectedRowKeys.length}</div>}
                  Action <ReactSVG className="icon" src="/icons/arrow_drop_down.svg" />
                </Button>
              </div>
            </div>

            <div className="ContentMain">
              <HubsTable
                dataSource={hubs}
                pagination={pagination}
                withAction={withAction}
                rowKey="id"
                isFetching={false}
                onSelectChange={onSelectChange}
                onChange={handleTableChange}
              />
            </div>
          </>
        )}

        {viewMode === 'map_view' && (
          <div className="map-view">
            <div className="wrapper-map">
              <GoogleMap
                markers={markers}
                center={
                  currentHub && selectedHubId
                    ? {
                        lat: currentHub.startAddressLat,
                        lng: currentHub.startAddressLng,
                      }
                    : null
                }
              />
            </div>
            {currentHub?.fleets?.length > 0 && selectedHubId && (
              <div className="fleets-list">
                <div className="title">
                  <h2>{currentHub.name}</h2>
                  <Button type="link" onClick={handleCancelSelectedHub}>
                    {' '}
                    <CloseOutlined />{' '}
                  </Button>
                </div>
                <FleetsTable dataSource={currentHub.fleets} columns={fleetsColumns} pagination={false} />
              </div>
            )}
          </div>
        )}

        <Modal open={stateModal.open} onCancel={handleCancelModal} footer={[]} width={792} forceRender>
          <HubForm
            handleSubmit={handleSubmitModal}
            handleCancel={handleCancelModal}
            hub={stateModal.hub}
            action={stateModal.action}
            isFetching={isFetching}
          />
        </Modal>
      </div>
    </Spin>
  );
};

export default HubsPage;
