/* eslint-disable max-len */
const Icon = () => {
  return `<svg width="40" height="45" viewBox="0 0 40 45" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <g opacity="0.24" filter="url(#filter0_f_372_12663)">
  <ellipse cx="20" cy="36" rx="12" ry="4" fill="#888888"/>
  </g>
  <g filter="url(#filter1_dd_372_12663)">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M23.0834 31.6593C29.332 30.2549 34 24.6726 34 18C34 10.268 27.732 4 20 4C12.268 4 6 10.268 6 18C6 24.6726 10.668 30.2549 16.9166 31.6593L20 37L23.0834 31.6593Z" fill="white"/>
  <path d="M23.0834 31.6593L22.7545 30.1958C22.3457 30.2877 21.9939 30.5464 21.7844 30.9093L23.0834 31.6593ZM16.9166 31.6593L18.2156 30.9093C18.0061 30.5464 17.6543 30.2877 17.2455 30.1958L16.9166 31.6593ZM20 37L18.701 37.75C18.9689 38.2141 19.4641 38.5 20 38.5C20.5359 38.5 21.0311 38.2141 21.299 37.75L20 37ZM32.5 18C32.5 23.9558 28.3333 28.9419 22.7545 30.1958L23.4124 33.1228C30.3307 31.5678 35.5 25.3894 35.5 18H32.5ZM20 5.5C26.9036 5.5 32.5 11.0964 32.5 18H35.5C35.5 9.43959 28.5604 2.5 20 2.5V5.5ZM7.5 18C7.5 11.0964 13.0964 5.5 20 5.5V2.5C11.4396 2.5 4.5 9.43959 4.5 18H7.5ZM17.2455 30.1958C11.6667 28.9419 7.5 23.9558 7.5 18H4.5C4.5 25.3894 9.66934 31.5678 16.5876 33.1228L17.2455 30.1958ZM21.299 36.25L18.2156 30.9093L15.6175 32.4093L18.701 37.75L21.299 36.25ZM21.7844 30.9093L18.701 36.25L21.299 37.75L24.3825 32.4093L21.7844 30.9093Z" fill="#4F8CE0"/>
  </g>
  <circle cx="20" cy="18" r="10" fill="url(#pattern0)"/>
  <defs>
  <filter id="filter0_f_372_12663" x="4" y="28" width="32" height="16" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
  <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_372_12663"/>
  </filter>
  <filter id="filter1_dd_372_12663" x="0.5" y="0.5" width="39" height="44" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset/>
  <feGaussianBlur stdDeviation="0.5"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_372_12663"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset dy="2"/>
  <feGaussianBlur stdDeviation="2"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"/>
  <feBlend mode="normal" in2="effect1_dropShadow_372_12663" result="effect2_dropShadow_372_12663"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_372_12663" result="shape"/>
  </filter>
  <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
  <use xlink:href="#image0_372_12663" transform="scale(0.001)"/>
  </pattern>
  </defs>
  </svg>
  `;
};
export default Icon;
