import React from 'react';
import { Col, Form, Input, Row, TimePicker } from 'antd';
import { TIME_FORMAT } from '../../../constants';
import './DeliveryForm.scss';
import AddressInput from '../../Inputs/AddressInput';
import isEmpty from 'lodash/isEmpty';
import PhoneInput from 'react-phone-input-2';

const DeliveryForm = ({ form }) => {
  const handleOnChangeDeliveryFrom = (value) => {
    form.setFieldsValue({ deliveryTimeStart: value });
    if (isEmpty(value) || isEmpty(form.getFieldValue('deliveryTimeEnd'))) {
      resetErrorDeliveryTime();
      return;
    }
    if (form.getFieldValue('deliveryTimeEnd') < value) {
      form.setFields([
        {
          name: 'deliveryTimeStart',
          errors: ['Delivery From must be less than Delivery To'],
        },
      ]);
    }
  };

  const handleOnChangeDeliveryTo = (value) => {
    form.setFieldsValue({ deliveryTimeEnd: value });
    if (isEmpty(value) || isEmpty(form.getFieldValue('deliveryTimeStart'))) {
      resetErrorDeliveryTime();
      return;
    }
    if (form.getFieldValue('deliveryTimeStart') > value) {
      form.setFields([
        {
          name: 'deliveryTimeEnd',
          errors: ['Delivery To must be greater than Delivery From'],
        },
      ]);
    }
  };

  const resetErrorDeliveryTime = () => {
    form.setFields([
      {
        name: 'deliveryTimeStart',
        errors: [],
      },
      {
        name: 'deliveryTimeEnd',
        errors: [],
      },
    ]);
  };
  return (
    <div className={`DeliveryForm`}>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item label="Delivery Name" name="deliveryName" rules={[{ required: true, message: 'Required' }]}>
            <Input placeholder="Delivery Name" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Delivery Phone Number" name="deliveryPhone">
            <PhoneInput country={'us'} prefix="+" specialLabel="" enableSearch disableSearchIcon />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Delivery From" name="deliveryTimeStart">
            <TimePicker
              placeholder="Delivery From"
              format={TIME_FORMAT}
              minuteStep={30}
              style={{ width: '100%' }}
              onChange={handleOnChangeDeliveryFrom}
              onSelect={handleOnChangeDeliveryFrom}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Delivery To" name="deliveryTimeEnd">
            <TimePicker
              placeholder="Delivery To"
              format={TIME_FORMAT}
              minuteStep={30}
              style={{ width: '100%' }}
              onChange={handleOnChangeDeliveryTo}
              onSelect={handleOnChangeDeliveryTo}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Delivery Email" name="deliveryEmail" rules={[{ type: 'email', message: 'Invalid Email' }]}>
            <Input placeholder="Delivery Email" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Delivery Address"
            name="deliveryAddress"
            rules={[
              {
                validator: (_, value) => {
                  if (
                    (value?.address && value?.latLng?.lat && value?.latLng?.lng) ||
                    (isEmpty(value?.address) && routePlanType !== 'PDP')
                  ) {
                    return Promise.resolve();
                  }

                  if (isEmpty(value?.address) && routePlanType === 'PDP') {
                    return Promise.reject(new Error('Required'));
                  }

                  return Promise.reject(new Error('Invalid'));
                },
              },
            ]}
          >
            <AddressInput
              placeholder="Delivery Address"
              onSelect={(value) => form.setFieldsValue({ deliveryAddress: value })}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Delivery Duration" name="deliveryDuration">
            <Input placeholder="Delivery Duration" suffix="minutes" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="deliveryNote" label="Delivery Note">
            <Input placeholder="Note" />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default DeliveryForm;
