import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {
  doCreateScheduledForm,
  doDeleteScheduledForm,
  doGetScheduledForm,
  doGetScheduledForms,
  doImportFromRangeDateSchedulerForm,
  doSynchronizeSchedulerForm,
  doUpdateScheduledForm,
} from './thunk';

const isActionWithPending = (action) => {
  return action.type && action.type.startsWith('ScheduledForms/') && action.type.endsWith('/pending');
};

const isActionWithFulfilled = (action) => {
  return action.type && action.type.startsWith('ScheduledForms/') && action.type.endsWith('/fulfilled');
};

const isActionWithFailed = (action) => {
  return action.type && action.type.startsWith('ScheduledForms/') && action.type.endsWith('/rejected');
};

const scheduledFormsAdapter = createEntityAdapter({
  selectId: (item) => item.id,
});

const scheduledFormSlice = createSlice({
  name: 'scheduledForm',
  initialState: {
    action: null,
    isFetching: false,
    currentScheduledForm: null,
    status: 'idle',
    metadata: {},
    entities: [],
    ids: [],
    item: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetScheduledForms.pending, (state) => {
      state.action = 'getAll';
      state.status = 'loading';
    });
    builder.addCase(doGetScheduledForms.fulfilled, (state, action) => {
      const { schedulerForms, pagination } = action.payload;
      state.status = 'succeeded';
      state.metadata = pagination;
      scheduledFormsAdapter.setAll(state, schedulerForms);
    });
    builder.addCase(doGetScheduledForms.rejected, (state) => {
      state.status = 'failed';
      scheduledFormsAdapter.setAll(state, []);
    });
    builder.addCase(doGetScheduledForm.pending, (state) => {
      state.action = 'getOne';
      state.status = 'loading';
    });
    builder.addCase(doGetScheduledForm.fulfilled, (state, action) => {
      state.status = 'succeeded';
      if (action.payload) {
        state.current = action.payload;
      }
    });
    builder.addCase(doGetScheduledForm.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doCreateScheduledForm.pending, (state) => {
      state.action = 'create';
      state.status = 'loading';
    });
    builder.addCase(doCreateScheduledForm.fulfilled, (state) => {
      state.status = 'succeeded';
    });
    builder.addCase(doCreateScheduledForm.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doUpdateScheduledForm.pending, (state) => {
      state.action = 'update';
      state.status = 'loading';
    });
    builder.addCase(doUpdateScheduledForm.fulfilled, (state) => {
      state.status = 'succeeded';
    });
    builder.addCase(doUpdateScheduledForm.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doDeleteScheduledForm.pending, (state) => {
      state.action = 'delete';
      state.status = 'loading';
    });
    builder.addCase(doDeleteScheduledForm.fulfilled, (state) => {
      state.status = 'succeeded';
    });
    builder.addCase(doDeleteScheduledForm.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doSynchronizeSchedulerForm.pending, (state) => {
      state.action = 'synchronize';
      state.status = 'loading';
    });
    builder.addCase(doSynchronizeSchedulerForm.fulfilled, (state) => {
      state.status = 'succeeded';
    });
    builder.addCase(doSynchronizeSchedulerForm.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doImportFromRangeDateSchedulerForm.pending, (state) => {
      state.action = 'importFromRangeDate';
      state.status = 'loading';
    });
    builder.addCase(doImportFromRangeDateSchedulerForm.fulfilled, (state) => {
      state.status = 'succeeded';
    });
    builder.addCase(doImportFromRangeDateSchedulerForm.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addMatcher(isActionWithPending, (state) => {
      state.isFetching = true;
      state.message = null;
    });
    builder.addMatcher(isActionWithFulfilled, (state) => {
      state.isFetching = false;
    });
    builder.addMatcher(isActionWithFailed, (state) => {
      state.isFetching = false;
      state.message = null;
    });
  },
});

export const {
  selectAll: selectAllScheduledForms,
  selectById: selectScheduledFormById,
  selectIds: selectScheduledFormIds,
} = scheduledFormsAdapter.getSelectors((state) => state.schedulerForm);
const { reducer: scheduledReducer } = scheduledFormSlice;
export default scheduledReducer;
