import request from '../../utils/request';

export function getHubs(params) {
  return request('/hubs', {
    method: 'GET',
    params: params,
  });
}

export function getHub(id) {
  return request(`/hubs/${id}`, {
    method: 'GET',
  });
}

export function createHub(data) {
  return request('/hubs', {
    method: 'POST',
    data,
  });
}

export function updateHub(data) {
  const id = data.id;
  delete data.id;
  return request(`/hubs/${id}`, {
    method: 'PATCH',
    data,
  });
}
