import React, { useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import { Link, useNavigate } from 'react-router-dom';
import OrderForm from '../components/Forms/OrderForm';
import { useDispatch, useSelector } from 'react-redux';
import { doCreateOrder } from '../modules/orders/stores/thunk';

const NewOrderPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isFetching, action, status } = useSelector((state) => state.order);

  useEffect(() => {
    if (['create'].includes(action) && status === 'succeeded') {
      navigate('/admin/orders');
    }
  }, [isFetching]);
  const handleSubmit = (values) => {
    dispatch(doCreateOrder(values));
  };
  return (
    <div className="Content OrdersNewPage">
      <div className="ContentHeader">
        <div className="HeaderTitle">
          <Link to={`/orders`}>Orders</Link>
          <ReactSVG className="icon" src="/icons/arrow-forward.svg" />
          <span className="Link">Add Single Order</span>
        </div>
      </div>
      <div className="ContentMain">
        <OrderForm isFetching={isFetching} handleSubmit={handleSubmit} />
      </div>
    </div>
  );
};

export default NewOrderPage;
